import Api from "../../Services/Api";

const api = new Api();

//this would be a single action but can be called to many components
//so the payload must be splitted by the component's name
export default (state,name,opts) => dispatch => {
  console.log('callAction:',state);
  console.log('callAction-Name:',name)
  console.log('callAction-opts:',opts)
  let _state = {
    ...state,
    type: "CALL_ACTION",
    data: opts,
    fetching:true
  }
  _state[ name ] = null;
  dispatch(_state);
  
  api.call(opts, true).then(response => {
    if (response.status === 1) {
        let new_state = {
            ...state,
            type: "CALL_ACTION_SUCCESS"
        }
        new_state[ name ] = response
        dispatch(new_state);
    } else {
        let new_state = {
            ...state,
            type: "CALL_ACTION_ERROR"
        }
        new_state[ name ] = response
        dispatch(new_state);
    }
  });
};
