import React, { useState, useEffect } from "react";
import LabelAndFilePicker from "./LabelAndFilePicker";
import LabelAndText from "./LabelAndText";
import LabelAndTextInput from "./LabelAndTextInput";
import { Button } from "@material-ui/core";

export default function (props) {
  const { label, onChange, value, action,inputLabel, uploadLabel } = props;
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [name, setName] = useState(null);
  const [file_url, setFileUrl] = useState(null);

  useEffect(() => {
    if(typeof value === 'undefined') return;
    if(value === null) return;
    if (files.length === 0 && value.length > 0) {
      let bulk = JSON.parse(value);
      setFiles(bulk);
    }
  }, [value]);

  useEffect(()=>{
      onChange(
        JSON.stringify(files)
      );
  },[
    update
    ]);
  return (
    <div>
      <div>
        <table width={"100%"}>
          {files.map((file, index) => {
            return (
              <tr>
                <td style={{borderBottom:'1px solid #ccc',paddingBottom:10}}>{file.name}</td>
               
                <td style={{borderBottom:'1px solid #ccc',paddingBottom:10}}>
                <Button variant={"contained"}  onClick={()=>{
                  document.location=file.value
                }} style={{marginRight:10}}>Download</Button>
                <Button variant={"contained"} onClick={()=>{
                  let f = files;
                  let _files = f.map((item)=>{
                    if(item.name !== file.name) return item;
                  })
                  setFiles(_files);
                }}>Hapus</Button></td>
              </tr>
            );
          })}
        </table>
        {toggle ? (
          <div style={{border: '1px solid #ccc',
                        padding: 10}}>
            <h4>Upload File</h4>
            <LabelAndTextInput
              label={inputLabel!== null ? inputLabel:"Judul"}
            
              onChange={(evt) => {
                setName(evt.target.value);
                setUpdate(update+1);
              }}
            />
            <LabelAndFilePicker
              label={uploadLabel !== null ? uploadLabel:"File"}
              action={action}
              onChange={(file_url) => {
                setFileUrl(file_url);
                setUpdate(update+1);
              }}
            />
            <div style={{marginTop:10}}>
               <Button variant={'contained'} onClick={()=>{
              let f = files;
              f.push({
                name,
                value:file_url
              })
              setFiles(f);
              setUpdate(update + 1);
              setToggle(false);
            }}>Tambah</Button>
            </div>
           
          </div>
        ) : <Button variant={"contained"} onClick={()=>{
          setToggle(true);
        }}>Upload</Button>}
      </div>
    </div>
  );
}
