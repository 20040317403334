import React from "react";

export default function Header(props) {
  const { moduleProps, modules } = props;
  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
        background: "#fff",
        padding: "0px 15px",
       
      }}
    >
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin:0,
        }}
      >
          <li style={{display:"inline-block"}}>
             <img src={require('../../assets/logo.png')}/>
          </li>
        {modules.map((item, index) => {
          return (
            <li
              style={{ display: "inline", marginRight: 30 }}
              key={index}
            >
        
              <a style={{ color: "#333", fontWeight: "bold" }} href={item.slug}>
                {item.name}
              </a>
            </li>
          );
        })}
       
      </ul>
    </div>
  );
}
