/**
 * component account management for admin pusat
 */
import React from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  IconButton,
  Icon,
  CircularProgress,
} from "@material-ui/core";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import DashboardStyle from "../../styles/DashboardStyles";
import TopLoggedInNav from "../../common/TopLoggedInNav";

import Footer from "../../common/Footer";
import DashboardBreadcrumbs from "../../common/DashboardBreadcrumbs";
import { Actions } from "../../../redux/reducers";
import CallAction from "../../../redux/actions/CallAction";

import "video-react/dist/video-react.css"; // import css

import ModuleAddDialog from "../../ModuleAddDialog";
import ModuleEditDialog from "../../ModuleEditDialog";
import ListingTable from "../../widgets/ListingTable";

class AccountAdminContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
      snackbarText: "",
      open_edit_dialog: false,
      selectedRow: null,
      open_add_dialog: false,
      open_edit_dialog: false,
      deletedRow: null,
      fetching: false,
      documents: false,
      reload_files: false,
      selectedFile: null,
      confirm_delete: false,
      showSidebar: window.innerWidth < 600 ? false : true,
      accounts: [],
    };
   
    this.renderAccessDenied = this.renderAccessDenied.bind(this);
    this.loadAccounts = this.loadAccounts.bind(this);
    this.handleLoadAccount = this.handleLoadAccount.bind(this);
    this.handleAccountAdded = this.handleAccountAdded.bind(this);
    this.handleAccountUpdated = this.handleAccountUpdated.bind(this);
    this.handleAccountDeleted = this.handleAccountDeleted.bind(this);
  }

  componentDidMount() {
    const { CallAction, call_action, location } = this.props;

    window.addEventListener("resize", (event) => {
      this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", (event) => {
      this.setState({
        showSidebar: window.innerWidth < 600 ? false : true,
      });
    });
  }
  componentDidUpdate(_props, _state) {
    const { call_action } = this.props;
    const { reload_files } = this.state;

    /*if (_props.call_action.komparasi !== call_action.komparasi) {
        this.handleCompareData(call_action.komparasi);
      }*/

    if (_props.call_action.account !== call_action.account) {
      this.handleLoadAccount(call_action.account);
    }
    if (_props.call_action.add_account !== call_action.add_account) {
      this.handleAccountAdded(call_action.add_account);
    }
    if (_props.call_action.update_account !== call_action.update_account) {
      this.handleAccountUpdated(call_action.update_account);
    }
    if (_props.call_action.delete_account !== call_action.delete_account) {
      this.handleAccountDeleted(call_action.delete_account);
    }
  }
  isPemda() {
    let roles = localStorage.getItem("roles");
    if (roles.indexOf("pemda")) return true;

    return;
  }
  loadAccounts() {
    const { call_action, callAction } = this.props;
    let m_daerah_id = localStorage.getItem("mdid");
    callAction(call_action, "account", {
      endpoint: "/account/daerah/" + m_daerah_id,
      scenario: "get",
    });
  }
  handleLoadAccount(payload) {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    if (payload.status === 1)
      this.setState({
        accounts: payload.data,
        fetching: false,
      });
  }
  handleAccountAdded(payload) {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    //this.loadAccounts();
    this.setState({
      fetching: false,
      snackbar: true,
      open_add_dialog:false,
      snackbarText: "Akun telah berhasil ditambahkan",
    });
  }
  handleAccountUpdated(payload) {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    // this.loadAccounts();
    this.setState({
      fetching: false,
      snackbar: true,
      open_edit_dialog:false,
      snackbarText:
        `Akun ${this.state.selectedRow.username} telah berhasil diubah.`,
    });
  }
  handleAccountDeleted(payload) {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    // this.loadAccounts();
    this.setState({
        confirm_delete: false,
        fetching:false,
        snackbar:true,
        snackbarText: `Akun ${this.state.selectedRow.username} telah berhasil dihapus`
    });
  }
  renderAccessDenied() {
    const {
      classes,
      moduleProps,
      modules,
      history,
      location,
      setup_indikator_inovasi,
    } = this.props;

    const { ready, snackbar, snackbarText, accounts } = this.state;

    return (
      <div className={classes.root}>
        <TopLoggedInNav
          history={history}
          modules={modules}
          name={"account"}
        />
        <Grid container>
        x
          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                marginTop: 80,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <DashboardBreadcrumbs
                    items={[{ path: "/account", label: "Account" }]}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ width: "100%" }}>
                    <h1>ACCOUNT</h1>
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <h4>Mohon Maaf !</h4>
                  <p>Anda tidak diizinkan untuk meng-akses halaman ini !</p>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Snackbar
          open={snackbar}
          message={snackbarText}
          autoHideDuration={6000}
        ></Snackbar>
      </div>
    );
  }
  render() {
    const {
      classes,
      moduleProps,
      modules,
      history,
      location,
      setup_indikator_inovasi,
      crudAddAction,
      crudUpdateAction,
      crudDeleteAction,
      call_action,
      callAction,
    } = this.props;

    const {
      ready,
      snackbar,
      snackbarText,
      accounts,
      open_add_dialog,
      open_edit_dialog,
      confirm_delete,
      selectedRow,
      deletedRow,
      fetching,
    } = this.state;
    
    return (
      <div className={classes.root}>
        <TopLoggedInNav
          history={history}
          modules={modules}
          name={"account"}
        />
        <Grid container>
         
          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                marginTop: 80,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <DashboardBreadcrumbs
                    items={[{ path: "/account", label: "Account" }]}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ width: "100%" }}>
                    <h1>ACCOUNT</h1>
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div style={{ marginBottom: 10 }}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        this.setState({
                          open_add_dialog: true,
                        });
                      }}
                    >
                      Tambah Account
                    </Button>
                  </div>
                  {fetching ? (
                    <CircularProgress />
                  ) : (
                    <ListingTable
                      {...this.props}
                      onEdit={(row) => {
                        this.setState({
                          selectedRow: row,
                          open_edit_dialog: true,
                        });
                      }}
                      onDelete={(row) => {
                        this.setState({
                          deletedRow: row,
                          confirm_delete: true,
                        });
                      }}
                    />
                  )}

                  <div style={{ marginTop: 10 }}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        this.setState({
                          open_add_dialog: true,
                        });
                      }}
                    >
                      Tambah Account
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Snackbar
          open={snackbar}
          message={snackbarText}
          autoHideDuration={6000}
        ></Snackbar>

        <ModuleAddDialog
          customData={{
            mdid: localStorage.getItem("mdid"),
          }}
          open={open_add_dialog}
          moduleProps={moduleProps}
          data={
            typeof location.state !== "undefined" &&
            typeof location.state.data !== "undefined"
              ? location.state.data
              : null
          }
          onSubmit={(values) => {
            console.log(values);
            callAction(call_action, "add_account", {
              endpoint: "/account",
              data: values,
              scenario: "post",
            });
            this.setState({
              open_add_dialog: false,
              fetching: true,
            });
            // crudAddAction(moduleProps, values);
          }}
          onClose={() => {
            this.setState({
              open_add_dialog: false,
            });
          }}
          onError={(invalids) => {
            this.setState({
              on_add_error: true,
              invalids,
            });
          }}
        />
        <ModuleEditDialog
          open={open_edit_dialog}
          moduleProps={moduleProps}
          data={selectedRow}
          onSubmit={(values) => {
            callAction(call_action, "update_account", {
              endpoint: "/account/" + selectedRow.id,
              data: values,
              scenario: "post",
            });
            this.setState({
              open_add_dialog: false,
              fetching: true,
            });
          }}
          onClose={() => {
            this.setState({
              open_edit_dialog: false,
            });
          }}
        />

        <Dialog
          open={confirm_delete}
          onClose={() => {
            this.setState({
              confirm_delete: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Hapus Data ?"}</DialogTitle>
          <DialogContent>
            {deletedRow !== null
              ? "data `" +
                deletedRow.nama_lengkap +
                "` akan dihapus secara permanen. Apakah anda yakin ?"
              : ""}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  confirm_delete: false,
                });
              }}
              color="primary"
            >
              BATALKAN
            </Button>
            <Button
              onClick={() => {
                callAction(call_action, "delete_account", {
                  endpoint: "/account",
                  scenario: "delete",
                  data: {
                    id: deletedRow.id,
                  },
                });
                this.setState({
                  fetching: true,
                  confirm_delete: false,
                });
              }}
              color="primary"
              autoFocus
            >
              HAPUS
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  
}

const mapStateToProps = (state) => {
  const {
    call_action,
    indikator_inovasi_list,
    get_dokumen_inovasi,
    crud,
    crud_add,
    crud_delete,
    crud_update,
    crud_get,
    do_action,
    setup_indikator_inovasi,
  } = state;

  return {
    crud,
    crud_add,
    crud_delete,
    crud_update,
    crud_get,
    do_action,
    call_action,
    indikator_inovasi_list,
    get_dokumen_inovasi,

    setup_indikator_inovasi,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
  setupIndikatorInovasiAction: (data) =>
    dispatch(Actions.setupIndikatorInovasiAction(data)),
  indikatorInovasiListAction: (data) =>
    dispatch(Actions.indikatorInovasiListAction(data)),
  updateIndikatorInovasiAction: (data) =>
    dispatch(Actions.updateIndikatorInovasiAction(data)),
  addDokumenInovasiAction: (data) =>
    dispatch(Actions.addDokumenInovasiAction(data)),
  getDokumenInovasiAction: (data) =>
    dispatch(Actions.getDokumenInovasiAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyle)(AccountAdminContainer));
