/**
 * reference: https://www.npmjs.com/package/reactjs-file-uploader
 */
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import Api from "../../Services/Api";
const api = new Api();

export default function LabelAndFilePicker(props) {
  const { label, onChange, value, action } = props;
  const [progress, setProgress] = useState(false);
  const [fileurl, setFileUrl] = useState("");
  const [file, setFile] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState(null);

  return (
    <div>
      <div>
            <h4>{label}</h4>
            {progress ? (
                <CircularProgress/>
            ) : null}
            {file!==null ? (
                <div><a href={fileurl}>{file.name}</a></div>
            ) : null}
             {file === null && typeof value !== 'undefined'  ? (
              <div>
               <div><a href={value}>{"Download File"}</a></div>
              </div>
            ) : null}
            <input
              type="file"
              name="file"
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow"
              onChange={event => {
               
                setFile(event.target.files[0]);
                setProgress(true);
                api
                  .upload(
                    "/media/upload",
                    {
                      file: event.target.files[0]
                    },
                    true
                  )
                  .then(response => {
                    setProgress(false);
                    setFileUrl(response.file_url);
                    onChange(response.file_url);
                  })
                  .catch(err => {
                    setProgress(false);
                    console.log(err.message);
                  });
              }}
            />
          </div>
    </div>
  );
}
