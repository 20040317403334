import React from "react";
import { Redirect } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
//import Menu from "./Menu";
import moment from "moment";
import DashboardStyle from "./styles/DashboardStyles";
import Header from "./common/header";


//const api = new Api();

const modules = [
    {
        name:"Questions",
        slug:"questions",
        service:"http://localhost:3090/questions",
        cruds:true,
    },
    {
        name:"Contents",
        slug:"contents",
        service:"http://localhost:3090/contents",
        cruds:true,
    }
];

class SettingsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
      snackbarText: "",
    };
  }
  
  render() {
    const { classes,moduleProps,modules } = this.props;
    
    return (
      <div className={classes.root}>
            <Header moduleProps={moduleProps} modules={modules} />
            <div style={{marginTop:60,padding:15}}>
              <h2>Settings</h2>
            </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    login,
    upcoming_recall,
  } = state;
  return {
    login,
    upcoming_recall,
  };
};

const mapDispatchToProps = dispatch => ({
 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyle)(SettingsContainer));
