import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
export default function ModuleWrapper(ComponentToInject, moduleProps, modules) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }
    render() {
      return <ComponentToInject {...this.props} modules={modules} moduleProps={moduleProps} />;
    }
  }
}