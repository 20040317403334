import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

export default function DashboardBreadcrubs(props){
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/" onClick={() => {}}>
                Dashboard
            </Link>
            <Link color="inherit" href="/" onClick={() => {}}>
                Welcome
            </Link>
        </Breadcrumbs>
    )
}