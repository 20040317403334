import LoginContainer from "./Components/LoginContainer";
import DashboardContainer from "./Components/DashboardContainer";
import ModuleContainer from "./Components/ModuleContainer";
import SettingsContainer from "./Components/SettingsContainer";
import ResetToken from "./Components/ResetToken";
import AccountAdminContainer from "./Components/screens/account/AccountAdminContainer";
import CampaignDetail from "./Components/screens/campaigns/CampaignDetail";

import PhotoIcon from '@material-ui/icons/Photo';
import moment from "moment";
const modules = [
  {
    name: "Dashboard",
    slug: "dashboard",
    crud: false,
    component: DashboardContainer,
  },
  {
    name: "CMS",
    divider: true,
    module: false,
    crud: false,
  },
  {
    name: "Pages",
    slug: "pages",
    singularName: "Page",
    crud: true,
    endpoint: "/crud/pages",
    updateLabel: "page",
    meta: {
      delete_flag: true,
    },
   
    delete: false,
    add: true,
    update: true,
    edit: true,
    access: ["admin","cms"],
    fields: [
      {
        label: "Title",
        name: "title",
        type: "text",
      },
      {
        label: "Slug",
        name: "slug",
        type: "text",
      },
      {
        label: "Summary",
        name: "summary",
        type: "textarea",
      },
      {
        label: "Content",
        name: "content",
        type: "richtext",
        visible: false,
      },
      {
        label: "Main Banner",
        name: "banner_url",
        type: "image",
        visible: false,
      },
      {
        label: "Thumbnail Image",
        name: "thumb_url",
        type: "image",
      },
    ],
  },
  {
    name: "Profil Shiokaya",
    divider: true,
    module: false,
    crud: false,
    access: ["admin"],
  },
  {
    name: "Pretests",
    slug: "pretests",
    singularName: "pretests",
    crud: true,
    endpoint: "/crud/pretests",
    updateLabel: "pretests",
    access: ["admin"],
    fields: [
      {
        label: "Question",
        name: "question",
        type: "textarea",
      },
      {
        label: "Answer 1",
        name: "answer1",
        type: "textarea",
      },
      {
        label: "Answer 2",
        name: "answer2",
        type: "textarea",
      },
      {
        label: "Slot 1",
        name: "answer_slot1",
        type: "lookup",
        values: ["left", "right", "up", "down","upperleft","upperright","lowerleft","lowerright"],
        resource_label: (row) => {
          return row.answer_slot1;
        },
      },
      {
        label: "Slot 2",
        name: "answer_slot2",
        type: "lookup",
        values: ["left", "right", "up", "down","upperleft","upperright","lowerleft","lowerright"],
        resource_label: (row) => {
          return row.answer_slot2;
        },
      },
      {
        label: "Type",
        name: "question_type",
        type: "lookup",
        values: ["left-right", "up-down","upperleft-lowerright","upperright-lowerleft"],
        resource_label: (row) => {
          return row.question_type;
        },
      },
    ],
  },
  {
    name: "Questions",
    slug: "questions",
    singularName: "question",
    crud: true,
    endpoint: "/question",
    updateLabel: "question",
    access: ["admin"],
    fields: [
      {
        label: "Pertanyaan Nomor:",
        name: "group_id",
        type: "lookup",
        resource: "question-groups",
        key: "id",
        resource_label: "no",
        resource_label: (row) => {
          return row.group.no;
        },
        value: (row) => {
          return row.no + "(" + row.answer_type + ")";
        },
      },
      {
        label: "Question",
        name: "question",
        type: "text",
      },
      {
        label: "Answer 1",
        name: "answer_1",
        type: "text",
      },
      {
        label: "Answer 2",
        name: "answer_2",
        type: "text",
      },
      {
        label: "Answer 3",
        name: "answer_3",
        type: "text",
      },
      {
        label: "Slot 1",
        name: "slot_1",
        type: "lookup",
        values: ["C1", "C2", "C3", "R1", "R2", "R3", "An", "A", "V", "K"],
        resource_label: (row) => {
          return row.slot_1;
        },
      },
      {
        label: "Slot 2",
        name: "slot_2",
        type: "lookup",
        values: ["C1", "C2", "C3", "R1", "R2", "R3", "An", "A", "V", "K"],
        resource_label: (row) => {
          return row.slot_2;
        },
      },
      {
        label: "Slot 3",
        name: "slot_3",
        type: "lookup",
        values: ["C1", "C2", "C3", "R1", "R2", "R3", "An", "A", "V", "K"],
        resource_label: (row) => {
          return row.slot_3;
        },
      },
    ],
  },

  {
    name: "Question Groups",
    slug: "question-groups",
    singularName: "group",
    crud: true,
    endpoint: "/question-groups",
    updateLabel: "no",
    access: ["admin"],
    fields: [
      {
        label: "Answer Type",
        name: "answer_type",
        type: "lookup",
        values: ["left-right", "top-down", "AnA", "VK"],
        resource_label: (data) => {
          return data.answer_type;
        },
      },
      {
        label: "Question No.",
        name: "no",
        type: "number",
      },
    ],
  },
  {
    name: "Contents",
    slug: "contents",
    singularName: "content",
    crud: true,
    endpoint: "/contents",
    updateLabel: "title",
    access: ["admin"],
    fields: [
      {
        label: "Slug",
        name: "slug",
        type: "text",
      },
      {
        label: "Title",
        name: "title",
        type: "text",
      },
      {
        label: "Tagline",
        name: "tagline",
        type: "text",
        visible: false,
      },
      {
        label: "Fungsi",
        name: "fungsi",
        type: "text",
        visible: false,
      },
      {
        label: "Karakter",
        name: "karakter",
        type: "richtext",
        visible: false,
      },
      {
        label: "Penjelasan",
        name: "summary",
        type: "richtext",
        visible: false,
      },
      {
        label: "Nama",
        name: "nama",
        type: "text",
      },
      {
        label: "Simbol",
        name: "simbol",
        type: "text",
        visible: false,
      },
      /*
        {
          label: "Text",
          name: "content_text",
          type: "richtext",
          visible: false //visible in listing ?
        },*/

      {
        label: "Deskripsi Singkat",
        name: "deskripsi_singkat",
        type: "richtext",
        visible: false,
      },
      {
        label: "Deskripsi Karakter",
        name: "deskripsi_karakter",
        type: "richtext",
        visible: false,
      },
      {
        label: "Deskripsi Element",
        name: "deskripsi_element",
        type: "richtext",
        visible: false,
      },
      {
        label: "Deskripsi Warna",
        name: "deskripsi_warna",
        type: "richtext",
        visible: false,
      },
      {
        label: "Sisi Produktif",
        name: "sisi_produktif",
        type: "richtext",
        visible: false,
      },
      {
        label: "Sisi Tantangan",
        name: "sisi_tantangan",
        type: "richtext",
        visible: false,
      },
      {
        label: "Kekuatan",
        name: "kekuatan",
        type: "text",
        visible: false,
      },
      {
        label: "Self Leadership",
        name: "self_leadership",
        type: "richtext",
        visible: false,
      },
      {
        label: "Karakter Alami",
        name: "karakter_alami",
        type: "richtext",
        visible: false,
      },
      {
        label: "Faktor Kesuksesan",
        name: "faktor_kesuksesan",
        type: "richtext",
        visible: false,
      },
      {
        label: "Faktor Kegagalan",
        name: "faktor_kegagalan",
        type: "richtext",
        visible: false,
      },
      {
        label: "Dukungan yang dapat diperoleh",
        name: "dukungan",
        type: "richtext",
        visible: false,
      },
      {
        label: "Brief 1",
        name: "brief1",
        type: "richtext",
        visible: false, //visible in listing ?
      },
      {
        label: "Brief 2",
        name: "brief2",
        type: "richtext",
        visible: false, //visible in listing ?
      },
      {
        label: "Brief 3",
        name: "brief3",
        type: "richtext",
        visible: false, //visible in listing ?
      },
      {
        label: "Photo",
        name: "photo_url",
        type: "image",
      },
      {
        label: "Logo",
        name: "logo_url",
        type: "image",
      },
      {
        label: "Attribute",
        name: "attribute",
        type: "text",
        visible: false,
      },
      {
        label: "Color",
        name: "color",
        type: "text",
        visible: false,
      },
      {
        label: "Intro",
        name: "intro",
        type: "richtext",
        visible: false,
      },
      {
        label: "Profil Bayangan",
        name: "profil_bayangan",
        hint: "contoh: bard|wizard|alchemist",
        type: "text",
        visible: false,
      },
      {
        label: "Dukungan Primer",
        name: "dukungan_primer",
        hint: "contoh: bard|wizard|alchemist",
        type: "text",
        visible: false,
      },
      {
        label: "Dukungan Sekunder",
        name: "dukungan_sekunder",
        hint: "contoh: bard|wizard|alchemist",
        type: "text",
        visible: false,
      },
      {
        label: "Konflik Primer",
        name: "konflik_primer",
        hint: "contoh: bard",
        type: "text",
        visible: false,
      },
      {
        label: "Konflik Sekunder",
        name: "konflik_sekunder",
        hint: "contoh: bard|wizard|alchemist",
        type: "text",
        visible: false,
      },
      {
        label: "Konflik Tersier",
        name: "konflik_tersier",
        hint: "contoh: bard|wizard|alchemist",
        type: "text",
        visible: false,
      },
    ],
  },

  {
    name: "Copytexts",
    slug: "texts",
    singularName: "content",
    crud: true,
    endpoint: "/crud/texts",
    updateLabel: "title",
    access: ["admin"],
    fields: [
      {
        label: "Slug",
        name: "slug",
        type: "text",
      },
      {
        label: "Name",
        name: "name",
        type: "text",
      },
      {
        label: "Content",
        name: "content",
        type: "richtext",
      },
    ],
  },
  {
    name: "Profesi",
    slug: "profesi",
    singularName: "profesi",
    crud: true,
    endpoint: "/crud/profesi",
    updateLabel: "title",
    access: ["admin"],
    fields: [
      {
        label: "Slug",
        name: "slug",
        type: "lookup",
        resource: "crud/contents",
        key: "slug",
        value: (data) => {
          return data.title;
        },
        resource_label: (data) => {
          return data.slug;
        },
      },
      {
        label: "Name",
        name: "name",
        type: "text",
      },
      {
        label: "Profesi",
        name: "profesi",
        type: "text",
      },
    ],
  },
  {
    name: "Industri",
    slug: "industri",
    singularName: "industri",
    crud: true,
    endpoint: "/crud/industri",
    updateLabel: "title",
    access: ["admin"],
    fields: [
      {
        label: "Slug",
        name: "slug",
        type: "lookup",
        resource: "crud/contents",
        key: "slug",
        value: (data) => {
          return data.title;
        },
        resource_label: (data) => {
          return data.slug;
        },
      },
      {
        label: "Industri",
        name: "jenis",
        type: "lookup",
        values: ["Property", "Education", "Finance", "Manufacture"],

        resource_label: (data) => {
          return data.jenis;
        },
      },

      {
        label: "Profesi",
        name: "profesi",
        type: "text",
      },
    ],
  },
  {
    name: "Payment Confirmation",
    slug: "payment-confirmations",
    singularName: "Payment Confirmation",
    crud: true,
    endpoint: "/confirmation/pending",
    updateLabel: "email",
    access: ["admin"],
    actions: [
      {
        icon: "settings",
        label: "Activate",
        uri: "/confirmation/activate",
      },
      {
        icon: "edit",
        label: "Reset Token",
        //uri:'/confirmation/activate',
        component: "reset_token",
      },
    ],
    edit: false,
    delete: false,
    add: true,
    fields: [
      {
        label: "Nama",
        name: "session_id",
        type: "lookup",
        searchable:true,
        resource: "crud/accounts",
        key: "session_id",
        value: (data) => {
          return {
            label:data.name + " - " + data.email + " - " + data.phone,
            value:data.session_id
          }
        },
        resource_label: (data) => {
          if (typeof data.account === "undefined") return "N/A";
          if (data.account === null) return "N/A";
          return data.account.name;
        },
      },
      {
        label: "Email",
        name: "account_email",
        type: "auto",
        input: false,
        value: (data) => {
          if (typeof data.account === "undefined") return "N/A";
          if (data.account === null) return "N/A";
          return data.account.email;
        },
        resource_label: (data) => {
          if (typeof data.account === "undefined") return "N/A";
          if (data.account === null) return "N/A";
          return data.account.email;
        },
      },

      {
        label: "Phone",
        name: "account_phone",
        type: "auto",
        input: false,
        resource_label: (data) => {
          if (typeof data.account === "undefined") return "N/A";
          if (data.account === null) return "N/A";
          return data.account.phone;
        },
      },
      {
        label: "Email Ketika Konfirmasi",
        name: "email",
        type: "text",
      },
      {
        label: "Nomor Rekening",
        name: "nomor_rekening",
        type: "text",
      },
      {
        label: "Nama Rekening",
        name: "nama_rekening",
        type: "text",
      },
      {
        label: "Nama Bank",
        name: "nama_bank",
        type: "text",
      },
      {
        label: "Cabang Bank",
        name: "cabang_bank",
        type: "text",
      },
      {
        label: "Nomor Telpon Waktu Konfirmasi",
        name: "nomor_telp",
        type: "text",
      },

      {
        label: "Tanggal Transfer",
        name: "tanggal_transfer",
        type: "datetime",
      },
      {
        label: "Jumlah",
        name: "jumlah",
        type: "number",
      },
      {
        label: "Profil",
        name: "profil",
        type: "auto",
        readonly: true,
        resource_label: (data) => {
          if (data.results !== null) {
            const {
              creator,
              star,
              mechanic,
              supporter,
              dealmaker,
              trader,
              accumulator,
              lord,
            } = data.results;
            return (
              "alchemist(" +
              mechanic +
              ") " +
              "wizard(" +
              creator +
              ") " +
              "bard(" +
              star +
              ") " +
              "knight(" +
              supporter +
              ") " +
              "arbiter(" +
              dealmaker +
              ") " +
              "merchant(" +
              trader +
              ") " +
              "priest(" +
              accumulator +
              ") " +
              "marshal(" +
              lord +
              ")"
            );
          } else {
            return "N/A";
          }
        },
      },
      {
        label: "Submit Date",
        name: "created_at",
        type: "datetime",
        readonly: true,
      },
      {
        label: "Status",
        name: "confirm_status",
        type: "enum",
        values: {
          0: "Pending",
          1: "Confirmed",
        },
      },
    ],
  },
  {
    name: "User Account",
    slug: "accounts",
    crud: false,
    component: AccountAdminContainer,
    endpoint: "/account/lookup",
    access: ["admin"],
    updateLabel:'name',
    fields: [
      {
        label: "Role",
        name: "role_id",
        type: "lookup",
        store: "role_id",
        resource:"crud/roles",
        key:"id",
        value:(data)=>{
          return data.name;
        },
        resource_label: (row) => {
          return row.role.name;
        },
      },
      {
        label: "ID",
        name: "id",
        type: "auto",
      },
      {
        label: "Name",
        name: "name",
        type: "text",
      },

      {
        label: "Email",
        name: "email",
        type: "text",
      },
      {
        label: "Phone",
        name: "phone",
        type: "text",
      },
      {
        label: "Password",
        name: "password",
        type: "password",
        visible: false,
      },
      {
        label: "Confirm Password",
        name: "confirm_password",
        type: "password",
        visible: false,
      },
    ],
  },
  {
    name: "Reset Token",
    slug: "reset_token",
    singularName: "reset",
    crud: false,
    component: ResetToken,
    access: ["admin"],
  },

  {
    name: "Blocks",
    slug: "blocks",
    singularName: "blocks",
    crud: true,
    endpoint: "/crud/blocks",
    updateLabel: "blocks",
    meta: {
      delete_flag: true,
    },
    actions: [],
    delete: true,
    add: true,
    update: true,
    edit: true,
    access: ["admin", "cms"],
    fields: [
      {
        label: "Name",
        name: "name",
        type: "text",
      },
       
      {
        label: "Block Title",
        name: "title",
        type: "text",
        visible:false,
      },
      {
        label: "Block Summary Text",
        name: "summary",
        type: "textarea",
        visible:false,
      },
      {
        label: "Content",
        name: "content",
        type: "richtext",
        visible:false,
      },
      {
        label: "Image",
        name: "image_url",
        type: "image",
      },
      {
        label: "Icon",
        name: "icon_url",
        type: "image",
        visible:false,
      },
     
      {
        label: "Related Page",
        name: "page",
        resource: "crud/pages",
        resource_label: (row) => {
          return row.page_name;
        },
        value: (row) => {
          return row.title;
        },
        key: "id",
        type: "lookup",
        visible:false,
      },
      {
        label: "Button Label",
        name: "button_label",
        type: "text",
        visible:false,
      },
      {
        label: "Button URL",
        name: "button_url",
        type: "text",
        visible:false,
      },
    ],
  },
  {
    name: "Payment Logs",
    slug: "payment-logs",
    singularName: "payment",
    crud: true,
    endpoint: "/crud/payment_logs",
    updateLabel: "trx_id",
    meta: {
      delete_flag: true,
    },
    actions: [],
    delete: false,
    add: false,
    update: false,
    edit: false,
    access: ["admin", "cms"],
    fields: [
      {
        label: "Date",
        name: "created_at",
        type: "datetime",
      },
      {
        label: "TRXID",
        name: "trx_id",
        type: "text",
      },
       
      {
        label: "Transaction ID",
        name: "transaction_id",
        type: "text",
        
      },
    
      {
        label: "Buyer Name",
        name: "buyer_name",
        type: "text",
        
      },
      {
        label: "Status",
        name: "status",
        type: "text",
        
      },
      {
        label: "Status Code",
        name: "status_code",
        type: "text",
        
      },
      {
        label: "Total",
        name: "total",
        type: "number",
        
      },
      {
        label: "Channel",
        name: "channel",
        type: "text",
        
      },
      {
        label: "VA",
        name: "va",
        type: "text",
        
      },
    ],
  },
  {
    name: "Settings",
    slug: "settings",
    singularName: "setting",
    crud: true,
    endpoint: "/crud/settings",
    updateLabel: "name",
    meta: {
      delete_flag: true,
    },
    actions: [],
    delete: true,
    add: true,
    update: true,
    edit: true,
    access: ["admin", "cms"],
    fields: [
      {
        label: "Name",
        name: "name",
        type: "text",
      },
      {
        label: "Value",
        name: "setting_value",
        type: "textarea",
      },
      
    ],
  },
  {
    name: "Voucher",
    slug: "voucher",
    singularName: "voucher",
    crud: true,
    endpoint: "/crud/vouchers",
    updateLabel: "name",
    meta: {
      delete_flag: true,
    },
    actions: [],
    delete: true,
    add: true,
    update: true,
    edit: true,
    access: ["admin"],
    fields: [
     
      {
        label: "Voucher Code",
        name: "code",
        type: "text",
      },
      {
        label: "Discount",
        name: "discount",
        type: "number",
      },
      {
        label: "Qty",
        name: "qty",
        type: "number",
      },
      {
        label: "Expired Time",
        name: "expired_time",
        type: "date",
      },
      {
        label: "#Redeems",
        name: "voucher_redeems",
        type: "number",
        resource_label:(row)=>{
          if(typeof row.voucher_redeems === 'undefined') return 0;
          return row.voucher_redeems.total;
        }
      },
      
    ],
  },
   {
    name: "Ad Campaigns",
    slug: "adcampaigns",
    singularName: "Campaign",
    crud: true,
    endpoint: "/crud/adcampaigns",
    updateLabel: "name",
    meta: {
      delete_flag: true,
    },
   
    delete: false,
    add: true,
    update: true,
    edit: true,
    access: ["admin","cms"],
    fields: [
      {
        label: "Campaign ID",
        name: "campaign_id",
        type: "auto",
        value: moment().unix()
      },
      {
        label: "Campaign Name",
        name: "name",
        type: "text",
      },
      {
        label: "Description",
        name: "description",
        type: "textarea",
      },
       {
        label: "Landing URL",
        name: "landing_url",
        type: "text",
      },
      
    ],
    actions:[
      {
        icon:PhotoIcon,
        label:"Assets",
        component:"adcampaign_assets",

      }
    ],
  },
  {
    visible:false,
    name: "Campaign Assets",
    slug: "adcampaign_assets",
    singularName: "asset",
    crud: true,
    endpoint: "/crud/adcampaigns_assets",
    updateLabel: "adcampaigns",
    backTo: "indikator",
    access: ["admin","cms"],
    parentFilters: [
      {
        field: "campaign_id",
        value: (data) => {
          return data.id;
        },
      },
    ], //default query filter
    
    addBefore: [CampaignDetail], //add component before content
    addAfter: [], // add component after content
    meta: {
      delete_flag: true,
    },
    actions: [],
    fields: [
        {
          label: "Campaign",
          name: "campaign_id",
          type: "auto",
          resource_label: (data) => {
            return data.name;
          },
          value: (data) => {
            console.log(data);
            return data.id;
          },
          text: (data) => {
            console.log(data);
            return data.name;
          },
        },
        {
          name:"name",
          label:"Asset Name",
          type:"text"
        },
        {
          name:"file",
          label:"Asset File",
          type:"image"
        },

      
    ],
  },
 
];

export default modules;
