import withAuth from "./withAuth";
import LoginContainer from "./Components/LoginContainer";
import LogoutContainer from "./Components/LogoutContainer";
import DashboardContainer from "./Components/DashboardContainer";
import ModuleContainer from "./Components/ModuleContainer";
import SettingsContainer from "./Components/SettingsContainer";

import ModuleWrapper from "./ModuleWrapper";
import modules from "./modules";
const getModules = () => {
  let routes = [];
  return modules.map((item, index) => {
    return {
      path: "/" + item.slug,
      exact: true,
      component: item.crud
        ? withAuth(ModuleWrapper(ModuleContainer, item, modules))
        : withAuth(ModuleWrapper(item.component, item, modules))
    };
  });
};
const routes = [
  {
    path: "/",
    exact: true,
    component: LoginContainer
  },
  {
    path: "/login",
    exact: true,
    component: LoginContainer
  },
  {
    path: "/logout",
    exact: true,
    component: LogoutContainer
  },

  ...getModules()
];

export default routes;
