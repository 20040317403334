import React from "react";
import { Redirect } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
//import Menu from "./Menu";
import moment from "moment";
import DashboardStyle from "./styles/DashboardStyles";
import Header from "./common/header";
import TopLoggedInNav from "./common/TopLoggedInNav";
import CallAction from "../redux/actions/CallAction";
//const api = new Api();

const modules = [
  {
    name: "Questions",
    slug: "questions",
    service: "http://localhost:3090/questions",
    cruds: true,
  },
  {
    name: "Contents",
    slug: "contents",
    service: "http://localhost:3090/contents",
    cruds: true,
  },
];

class ResetToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
      snackbarText: "",
      token:"",
      done: false,
      fetching: false
    };
    this.handleResetToken = this.handleResetToken.bind(this);
  }
  componentDidUpdate(_props, _states){
      if(this.props.call_action.reset_token !== _props.call_action.reset_token){
          this.handleResetToken(this.props.call_action.reset_token)
      }
  }
  handleResetToken(payload){
      if(typeof payload === 'undefined') return;
      if(payload === null) return;
      let done =  false;

      if(payload.status === 1) done = true;

      this.setState({
        fetching:false,
        done
    })
  }
  render() {
    const { classes, moduleProps, modules,history, location } = this.props;
    const {done, fetching, token} = this.state;
    let id = location.state.data.id;
  
    if(fetching) return (
        <div className={classes.root}>
        <TopLoggedInNav history={history} modules={modules} />
        <div
          style={{
            width: "100%",
            marginTop: 30,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
                <CircularProgress/>
            </div>
        </div>
    );
    return (
      <div className={classes.root}>
        <TopLoggedInNav history={history} modules={modules} />
        <div
          style={{
            width: "100%",
            marginTop: 30,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
        {done ? (<div>
            <h1>Token Berhasil di reset.</h1>
        </div>) : ( <div>
            <h1 style={{ color: "#333" }}>Reset Token</h1>
            <h3>Customer:</h3>
            {location.state.data.email}
            <h3>Token Baru: </h3>
            <input type="text" name="token" placeholder="Masukkan 6 digit token baru"
            onBlur={(evt)=>{
               
                this.setState({
                    token: evt.target.value
                })
            }}
            style={{
                padding:'10px 4px',
                fontWeight:700,
                fontSize:'130%'
            }}/>
            <div style={{marginTop:10}}>
            <Button variant={'contained'}
            onClick={()=>{
                this.props.callAction(this.props.call_action,'reset_token',{
                    endpoint:'/confirmation/pending/reactivate/' + id,
                    data:{
                        token
                    },
                    scenario:'get'
                })
                this.setState({
                    fetching:true
                })
            }}>KIRIM</Button>
            </div>
         
            
          </div>)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { call_action } = state;
  return {
   
    call_action
  };
};

const mapDispatchToProps = (dispatch) => ({
    callAction: (state, name, opts) => dispatch(CallAction(state, name, opts))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyle)(ResetToken));
