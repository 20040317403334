/**
 * reference: 
 * https://alligator.io/react/react-select/
 */
import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Api from "../../Services/Api";
import AsyncSelect from "react-select";

export default function ResourceSearchableLookup(props) {
  const {
    open,
    handleClose,
    handleOpen,
    onChange,
    id,
    label,
    resource,
    field,
    isMulti,
    multiValues,
    stores,
    
  } = props;

  const api = new Api();

  const [value, setValue] = useState(null);
  const [values, setValues] = useState([]);
  const [search, setSearch] = useState("");
  
  useEffect(() => {
    //retrieve lookup
    let endpoint = "/" + resource;
    console.log('stores nih : ',stores);
    if(typeof field.resource === 'function'){
      endpoint = field.resource(null, stores)
    }
    
    api
      .crud({
        endpoint,
        actionType: "LOOKUP",
        data: {
          search
        }
      })
      .then(response => {
       
        if(response.data.length > 0){
            let vals = [];
            
            response.data.map((item)=>{
              if(typeof field.value === 'function'){
                vals.push(field.value(item))
              }else{
                vals.push({
                  label: item[field.valueField],
                  value: item[field.key]
                })
              }
            })
            setValues(vals);
          
        }
        
        //setValue(value);
      })
      .catch(err => {
        console.log(err);
        setValues([]);
      });
  }, [resource, search, stores]);
  
  useEffect(() => {
    setValue(props.value);
  }, [values]);

  
  if (typeof values === "undefined") return "Loading...";
  if (value === null) return "..."
  
  return (
    <div key={id} style={{ marginTop: 15, marginBottom: 15 }}>
      <div
        style={{
          fontWeight: "bold",
          paddingBottom: 15
        }}
      >
        {label}
      </div>
      <div>
        <AsyncSelect 
        defaultValue={props.value}
        isMulti={isMulti}
        options={values} 
        onInputChange={(inputValue)=>{
            setSearch(inputValue)
        }} onChange={(item)=>{
         
            if(Array.isArray(item)){
              let strVal = [];
              item.map((t)=>{
                strVal.push(t.value);
              })
              onChange(strVal.join(","));
            }else{
              onChange(item.value)
            }
            
        }}/>
      </div>
    </div>
  );
}
