import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import LabelAndTextInput from "./widgets/LabelAndTextInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import LabelAndText from "./widgets/LabelAndText";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LabelAndNumberInput from "./widgets/LabelAndNumberInput";
import LabelAndTextArea from "./widgets/LabelAndTextArea";
import ArrayLookup from "./widgets/ArrayLookup";
import ResourceLookup from "./widgets/ResourceLookup";
import ResourceSearchableLookup from "./widgets/ResourceSearchableLookup";
import { DatetimePickerTrigger } from "rc-datetime-picker";

import LabelAndImagePicker from "./widgets/LabelAndImagePicker";
import LabelAndFilePicker from "./widgets/LabelAndFilePicker";
import LabelAndVideoPicker from "./widgets/LabelAndVideoPicker";
import LabelAndRadio from "./widgets/LabelAndRadio";

import unslug from "../libs/unslug";
import moment from "moment";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { userHasAccess } from "../libs/helper";
//import { Attachment } from "@material-ui/icons";
import Attachment from "./widgets/Attachment";

const skipAutofields = (values,fields) => {
  let v = {}
  fields.map(field => {
    if(field.type !== 'auto') v[field.name] = values[field.name]
  });
  return v;
}
const fixDates = (values, fields) => {
  let v = {}
  v = Object.assign(v, values);
  fields.map(field => {
    if (field.type === "datetime") {
      v[field.name] = moment(v[field.name]).format("YYYY-MM-DD hh:mm:ss");
    } else if (field.type === "date") {
      v[field.name] = moment(v[field.name]).format("YYYY-MM-DD");
    } else if (field.name === "created_at") {
      v[field.name] = moment(v[field.name]).format("YYYY-MM-DD hh:mm:ss");
    } else if (field.name === "updated_at") {
      v[field.name] = moment(v[field.name]).format("YYYY-MM-DD hh:mm:ss");
    }
  });
  return v;
};


export default function ModuleEditDialog(props) {
  const {
    open,
    onSubmit,
    onClose,
    moduleProps,
    data,
    upload,
    uploadAction,
    customData
  } = props;
  
  let [values, setValues] = useState(data);
  const [update, setUpdate] = useState(0);
  const [store, setStore] = useState(data);
  const fields =
    typeof moduleProps.fields !== "undefined" ? moduleProps.fields : [];
  useEffect(() => {
    if (data) {
      let new_data = {};

      for (let i in moduleProps.fields) {
        if (typeof moduleProps.fields[i].name !== "undefined") {
          let fieldName = moduleProps.fields[i].name.trim();
          if(data[fieldName] !== null){
            new_data[fieldName] = typeof data[fieldName] === 'object' ? data[fieldName].value : data[fieldName];
          }
         
        }
      }
     console.log('new_data:',new_data);
      setValues(new_data);
      setStore(data);
    }
  }, [data]);
  if (typeof data === "undefined") return "";
  if (data === null) return "";
  if (values === null) return "";

  const handleEditorChange = evt => {
    //evt.target.getContent()
  };
  const renderField = (field, index) => {
    if(typeof field.input !== 'undefined'){
      if(!field.input) return null;
    }
    //if there's access level set
    if (typeof field.access !== "undefined") {
      if (field.access.length > 0) {
        if (!userHasAccess(field.access)) return null;
      }
    }

    //show only if certain value met.

    if (typeof field.show_if !== "undefined") {
      if (!field.show_if(data)) return "";
    }
    if (field.readonly === true) {
      return (
        <LabelAndText
          key={index}
          id={index}
          text={
            typeof field.resource_label !== "undefined"
              ? field.resource_label(data)
              : values[field.name]
          }
          label={(field.label)}
        />
      );
    }
    if (field.type === "text") {
      return (
        <LabelAndTextInput
          key={index}
          id={index}
          label={(field.label)}
          value={values[field.name]}
          onChange={event => {
            let v = values;
            v[field.name] = event.target.value;
            setValues(v);
          }}
        />
      );
    }else if (field.type === "password") {
      return (
        <LabelAndTextInput
          key={index}
          id={index}
          label={(field.label)}
         
          onChange={event => {
            let v = values;
            v[field.name] = event.target.value;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "textarea") {
      return (
        <LabelAndTextArea
          key={index}
          id={index}
          value={values[field.name]}
          label={(field.label)}
          onChange={event => {
            let v = values;
            v[field.name] = event.target.value;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "label") {
      return (
        <LabelAndText
          key={index}
          label={field.label}
          labelSize={24}
          labelColor={"#cc0000"}
        />
      );
    } else if (field.type === "date") {
      let v = values;
      if (typeof v[field.name] === "undefined") v[field.name] = moment();
      else  v[field.name] = moment(v[field.name]);
      return (
        <div style={{ marginBottom: 15 }}>
          <h4 style={{ margin: 0, marginBottom: 5 }}>{field.label}</h4>
         
           <DatetimePickerTrigger
            moment={v[field.name]}
            showTimePicker={false}
            onChange={date => {
              v[field.name] = date;
              setValues(v);
              setUpdate(update + 1);
            }}
          >
            <input
              type="text"
              value={v[field.name].format("DD-MM-YYYY")}
              readOnly
              style={{
                padding: "6px 10px",
                fontSize: "100%",
                borderRadius: 4,
                border: "1px solid #ccc",
                marginTop: 10,
                width:'100%'
              }}
            />
          </DatetimePickerTrigger>
        </div>
      );
    } else if (field.type === "datetime") {

      let v = values;
      if (typeof v[field.name] === "undefined") v[field.name] = moment();
      else  v[field.name] = moment(v[field.name]);

      return (
        <div key={index} style={{ marginBottom: 15 }}>
          <h4 style={{ margin: 0, marginBottom: 5 }}>{field.label}</h4>
          <DatetimePickerTrigger
            moment={v[field.name]}
            onChange={date => {
              v[field.name] = date;
              setValues(v);
              setUpdate(update + 1);
            }}
          >
            <input
              type="text"
              value={v[field.name].format("DD-MM-YYYY HH:mm")}
              readOnly
              style={{
                padding: "6px 10px",
                fontSize: "100%",
                borderRadius: 4,
                border: "1px solid #ccc",
                marginTop: 10,
                width:'100%'
              }}
            />
          </DatetimePickerTrigger>
          </div>
      );
    } else if (field.type === "number") {
      return (
        <LabelAndNumberInput
          key={index}
          id={index}
          value={values[field.name]}
          label={(field.label)}
          onChange={val => {
            let v = values;
            v[field.name] = val;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "plugin") {
      let v = values;
      if (typeof v[field.name] === "undefined") v[field.name] = null;
      return field.component({
        data: v[field.name],
        field,
        onUpdate: data => {
         // console.log("data", data);
          v[field.name] = data;
          setValues(v);
        }
      });
    } else if (field.type === "richtext") {
      /* const contentHTML = convertFromHTML(values[field.name]);
      const contentState = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const content = JSON.stringify(convertToRaw(contentState));*/
      return (
        <div key={index} style={{ marginTop: 15, marginBottom: 15 }}>
          <div
            style={{
              fontWeight: "bold",
              paddingBottom: 15
            }}
          >
            {(field.label)}
          </div>
          <div
            style={{
              border: "1px solid #ccc",
              padding: 4
            }}
          >
            <CKEditor
              editor={ClassicEditor}
              data={values[field.name]}
              onInit={editor => {
                // You can store the "editor" and use when it is needed.
                // console.log( 'Editor is ready to use!', editor );
                
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log( { event, editor, data } );
              }}
              onBlur={(event, editor) => {
                let v = values;
                v[field.name] = editor.getData();
                setValues(v);
              }}
              onFocus={(event, editor) => {
                //  console.log( 'Focus.', editor );
              }}
            />
          </div>
        </div>
      );
    } else if (field.type === "image") {
      return (
        <LabelAndImagePicker
          key={index}
          value={values[field.name]}
          action={uploadAction}
          label="Foto Tersangka"
          onChange={file_uri => {
            let v = values;
            v[field.name] = file_uri;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "file") {
      return (
        <LabelAndFilePicker
          key={index}
          value={values[field.name]}
          action={uploadAction}
          label={field.label}
          onChange={file_uri => {
            let v = values;
            v[field.name] = file_uri;
            setValues(v);
          }}
        />
      );
    }else if (field.type === "attachment") {
      console.log(field);
      return (
        <Attachment
          key={index}
          value={values[field.name]}
          action={uploadAction}
          label={field.label}
          inputLabel={field.inputLabel}
          uploadLabel={field.uploadLabel}
          onChange={val => {
            let v = values;
            v[field.name] = val;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "video") {
      return (
        <LabelAndVideoPicker
          key={index}
          value={values[field.name]}
          action={uploadAction}
          label={field.label}
          onChange={file_uri => {
            let v = values;
            v[field.name] = file_uri;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "media") {
      return (
        <LabelAndTextInput
          placeholder={"http://"}
          key={index}
          id={index}
          value={values[field.name]}
          label={(field.label)}
          onChange={event => {
            let v = values;
            v[field.name] = event.target.value;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "auto") {
      if(field.label === null) return null;
      return (
        <LabelAndText
          key={index}
          id={index}
          text={
            typeof field.resource_label !== "undefined"
              ? field.resource_label(data)
              : values[field.name]
          }
          label={(field.label)}
        />
      );
    } else if (field.type === "lookup") {

      //look up can be triggered by special conditions
      //by checking the value stored by previous lookup fields.
      if(typeof field.triggerAfter !== 'undefined'){
        let triggerVal = store[field.triggerAfter];
        if(typeof triggerVal === 'undefined') return;
        if(triggerVal === null) return;

        //after we read the value from previous lookup
        //then we can check if these field require specific trigger condition 
        //like if one lookup should have these value, and another lookup field must contain specific value
        //before allowing these lookup field to be displayed.
        if(typeof field.triggerCondition !== 'undefined'){
          //console.log("pass condition : ", field.triggerCondition(store), store);
          if(!field.triggerCondition(store)) return;
        }
      }
      /*
      //pre-defined array of values
      if(typeof field.defaultValue === 'function'){
        values[field.name] = field.defaultValue(data)
      }*/
      if (typeof field.values !== "undefined"){
        if(typeof field.defaultValue === 'function'){
          values[field.name] = field.defaultValue(data)
        }
        return (
          <ArrayLookup
            key={index}
            id={field.name + "-" + moment().unix()}
            label={(field.label)}
            value={typeof field.value === 'function' ? field.value(data) : values[field.name]}
            values={field.values}
            stores={store}
            dataKey={field.key}
            onChange={value => {
             // console.log("arrayLookup changes : ", value);
              let v = values;
              v[field.name] = value;
              if(typeof field.store !== 'undefined'){
              //  console.log("store: ", value);
                let s = store;
                s[field.store] = value;
                setStore(s);
              }
              setValues(v);
            }}
          ></ArrayLookup>
        );
      }else if(field.searchable) {

        //handle multiple selection default values
        let _lookupValue = values[field.name];
        console.log("lookup default: ", values[field.name]);
        if(field.multiple !== 'undefined' && Array.isArray(values[field.name])){
          let aValues = [];
          values[field.name].map(tt=>{
              aValues.push(tt.value);
          })
          values[field.name] = aValues.join(",");
        }
        //->
        return (
          <ResourceSearchableLookup
            resource={field.resource}
            id={field.name + "-" + moment().unix()}
            key={index}
            value={typeof field.defaultValue === 'function' ? field.defaultValue(data) : _lookupValue}
            label={(field.label)}
            values={field.values}
            dataKey={field.key}
            stores={store}
            field={field}
            isMulti={typeof field.multiple !== 'undefined' ? true: false}
            onChange={value => {
              let v = values;
              v[field.name] = value;
              console.log("CHANGE:", v[field.name]);
              if(typeof field.store !== 'undefined'){
               // console.log("store: ", value);
                let s = store;
                s[field.store] = value;
                setStore(s);
              }
              setValues(v);
              
            }}
          />
        );
      }
      
      else
      //dynamic - the values is retrieved from database.
      return (
        <ResourceLookup
          resource={field.resource}
          id={field.name + "-" + moment().unix()}
          key={index}
          value={values[field.name]}
          label={(field.label)}
          values={field.values}
          dataKey={field.key}
          stores={store}
          field={field}
          onChange={value => {
            let v = values;
            v[field.name] = value;
            if(typeof field.store !== 'undefined'){
          
              let s = store;
              s[field.store] = value;
              setStore(s);
            }
            setValues(v);
          }}
        />
      );
    }else if (field.type === "radio") {
      //pre-defined array of values
     
        return (
          
          <LabelAndRadio
          key={index}
          id={field.name + "-" + moment().unix()}
          label={(field.label)}
          value={typeof field.value === 'function' ? field.value(values[field.name]) : values[field.name]}
          values={field.values}
          dataKey={field.key}
          onChange={value => {
            let v = values;
            v[field.name] = value;
            setValues(v);
          }}
        ></LabelAndRadio>
        );

     
    } else {
      return null;
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"md"} style={{top:60}}>
      <DialogTitle id="alert-dialog-slide-title">Modify Data</DialogTitle>
      <DialogContent>
        {fields.map((field, index) => {
          return renderField(field, index);
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Batalkan
        </Button>
        <Button
          onClick={() => {
            
            let final_values = fixDates(values, fields);
            
            final_values = skipAutofields(final_values, fields);
            //console.log('final:',final_values);
            onSubmit(final_values);
          }}
          color="primary"
        >
          SAVE CHANGES
        </Button>
      </DialogActions>
    </Dialog>
  );
}
