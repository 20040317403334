import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { RadioGroup, RadioButton } from "react-radio-buttons";

export default function LabelAndRadio(props) {
  const {
    open,
    handleClose,
    handleOpen,
    onChange,
    values,
   
    id,
    label,
    hint
  } = props;
  const [value, setValue] = useState(props.value);
  

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
 

  return (
    <div key={id} style={{ marginTop: 15, marginBottom: 15 }}>
      <div
        style={{
          fontWeight: "bold",
          paddingBottom: 15
        }}
      >
        {label}
      </div>
      <div style={{ marginBottom: 10 }}>    <p
        dangerouslySetInnerHTML={{ __html: hint }}
        ></p></div>

      <RadioGroup
        horizontal
        value={typeof value !== 'undefined' && value !== null ? value.toString() : ''}
        onChange={value => {
          setValue(value);
          onChange(value);
        }}
      >
        {values.map((item, index) => {
           // console.log("radio:", item);
            //if(typeof item.value === 'undefined') item.value="";

          if(item === null) item = "";
          return (
            <RadioButton
                rootColor={'#a0a0a0'}
                pointColor={'#000000'}
              key={index}
              value={typeof item === "object" ? item.value.toString() : item.toString()}
            >
              {typeof item === "object" ? item.text : item}
            </RadioButton>
          );
        })}
      </RadioGroup>
    </div>
  );
}
