import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function ArrayLookup(props) {
  const {
    open,
    handleClose,
    handleOpen,
    onChange,
    values,
    id,
    label,
    hint,
  } = props;
  const [value, setValue] = useState("");
  console.log(values);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div key={id} style={{ marginTop: 15, marginBottom: 15 }}>
      <div
        style={{
          fontWeight: "bold",
          paddingBottom: 15,
        }}
      >
        {label}
      </div>
      {hint ? (
        <div style={{ marginBottom: 10 }}>
          <p dangerouslySetInnerHTML={{ __html: hint }}></p>
        </div>
      ) : null}
      <Select
        labelId={id}
        id={id}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        style={{
          width: "100%",
        }}
        onChange={(evt) => {
          setValue(evt.target.value);
          onChange(evt.target.value);
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {values.map((item, index) => {
          return (
            <MenuItem
              key={index}
              value={typeof item === "object" ? item.value : item}
            >
              {typeof item === "object" ? item.text : item}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}
