import React,{useState} from "react";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";

export default function LabelAndTextArea(props) {
  const { label, value, onChange, onBlur, hint } = props;
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{
        marginBottom: "10px"
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          ...props.labelStyle
        }}
      >
        {label}
      </div>
      <div>
      {hint ? ( <p
        dangerouslySetInnerHTML={{ __html: hint }}
        ></p>) : null}
       
      </div>
      <div>
        <TextField
          id="outlined-basic"
          style={{
            width: "100%"
          }}
          defaultValue={value}
          multiline
          margin="normal"
          variant="outlined"
          onChange={onChange}
          onBlur={onBlur}
          inputProps={{
            style: {
              paddingTop: 8,
              paddingBottom: 8
            }
          }}
        />
      </div>
    </div>
  );
}
