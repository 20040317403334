import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Snackbar
} from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Actions } from "../redux/reducers";
import LoginContainerStyles from "./styles/LoginContainerStyles";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      fetching: false,
      success: false,
      session_id: "",
      snackbar: false,
      snackbarText: ""
    };
  }
  componentDidMount() {
    localStorage.clear();
  }
  componentDidUpdate(_props, _states) {
    const { login } = this.props;
    if (_props.login !== login) {
      if (_props.login.fetching !== login.fetching) {
        this.setState({
          fetching: login.fetching
        });
      }
      if (_props.login.payload !== login.payload && login.payload !== null) {
        if (typeof login.payload !== "undefined") {
          if(login.payload.status === 0) return   this.setState({
            snackbar: true,
            snackbarText: "Email dan Password yang anda masukkan salah !"
          });
          localStorage.setItem("token", login.payload.access_token);
          localStorage.setItem("session_id", login.payload.user.session_id);
          localStorage.setItem("name", login.payload.user.name);
          localStorage.setItem("roles", [login.payload.roles]);
          this.setState({ success: true });
        }
      }
      if (_props.login.error !== login.error) {
        if (login.error) {
          this.setState({
            snackbar: true,
            snackbarText: "Email dan Password yang anda masukkan salah !"
          });
        }
      }
    }
  }
  render() {
    const { classes } = this.props;
    const {
      email,
      token,
      fetching,
      success,
      session_id,
      snackbar,
      snackbarText
    } = this.state;

    if (success)
      return (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: { auth: true }
          }}
        />
      );

    return (
      <div className={classes.root}>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <h1>DASHBOARD</h1>
        </div>
        <Container className={classes.loginForm}>
          <Grid xs={12}>
            <TextField
              label="E-Mail"
              fullWidth
              variant="filled"
              placeholder="Masukan e-mail anda..."
              style={{ marginBottom: 30 }}
              onChange={evt => this.setState({ email: evt.target.value })}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Password"
              type="password"
              variant="filled"
              fullWidth
              placeholder="Masukan password anda..."
              onChange={evt => this.setState({ token: evt.target.value })}
            />
          </Grid>
          <Grid xs={12} className={classes.action}>
            {!fetching ? (
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => {
                  this.props.doLogin({
                    email,
                    password: token
                  });
                }}
              >
                LOGIN
              </Button>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Container>
        <Snackbar
          open={snackbar}
          message={snackbarText}
          autoHideDuration={6000}
        ></Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { login } = state;
  return {
    login
  };
};

const mapDispatchToProps = dispatch => ({
  doLogin: data => dispatch(Actions.loginAction(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LoginContainerStyles)(LoginContainer));
