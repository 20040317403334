import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Snackbar
} from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Actions } from "../redux/reducers";
import LoginContainerStyles from "./styles/LoginContainerStyles";

class LogoutContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      fetching: false,
      success: false,
      session_id: "",
      snackbar: false,
      snackbarText: ""
    };
  }
  componentDidMount() {
    localStorage.clear();
    document.location="/login";
  }
  componentDidUpdate(_props, _states) {
   
  }
  render() {
    return "Logout..";
  }
}

const mapStateToProps = state => {
  const { login } = state;
  return {
    login
  };
};

const mapDispatchToProps = dispatch => ({
  doLogin: data => dispatch(Actions.loginAction(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LoginContainerStyles)(LogoutContainer));
