import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import Api from "../../Services/Api";
import moment from "moment";
import { userHasAccess, text } from "../../libs/helper";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Icon,
  CircularProgress,
  Snackbar,
  Tab,
  Tabs,
  Modal,
} from "@material-ui/core";
export default function (props) {
  const { crud, moduleProps, modules, location, history, onEdit, onDelete } = props;

  const [tableRef, setTableRef] = useState(React.createRef());
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableQuery, setTableQuery] = useState(null);
  const [filters, setFilters] = useState(null);
  const [actionOpts, setactionOpts] = useState({});
  const [deletedRow, setDeletedRow] = useState(null);
  const [open_edit_dialog, setOpenEditDialog] = useState(false);
  const [confirm_delete, setConfirmDelete] = useState(false);

  const [actionName, setActionName] = useState(null);
  const [actionText, setActionText] = useState(null);
  const [actionUri, setActionUri] = useState(null);
  const [actionOpenWindow, setActionOpenWindow] = useState(null);
  const [confirm_action, setConfirmAction] = useState(false);
  const [open_action_component, setOpenActionComponent] = useState(null);
  const [other_action, setOtherAction] = useState(null);
  const [cols, setCols] = useState([]);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    if (update > 0) return;
    let [columns, data, pageSize] = getData();
    setCols(columns);
  }, [moduleProps]);

  const getFilters = function () {
    if (typeof moduleProps.parentFilters === "undefined") return;

    let filters = {};

    moduleProps.parentFilters.map((item) => {
      filters[item.field] = item.value(location.state.data);
    });

    return filters;
  };
  const getData = () => {
    let data = [];
    /*if (!moduleProps.crud) {
      if (typeof crud === "undefined") return [[], []];
      if (typeof crud.payload === "undefined") return [[], []];
      if (crud.payload === null) return [[], []];
      data = crud.payload.data;
    }*/

    let cols = [];
    if (data.length > 0) {
      let row = data[0];
      for (let k in row) {
        let field = getField(moduleProps.fields, k);
        if (!field) continue;
        cols = compileColumns(cols, field, k);
      }
    }
    if (cols.length === 0) {
      //if cols still empty, then we use the defined columns setup
      moduleProps.fields.map((field) => {
        let k = field.name;
        cols = compileColumns(cols, field, k);
      });
    }

    return [cols, data, 5];
  };
  const isVisible = (field) => {
    if (typeof field.visible === "undefined") return true;
    return field.visible;
  };
  const compileColumns = (cols, field, k) => {
    let fields = [];

    if (typeof field.access !== "undefined") {
      if (field.access.length > 0) {
        if (!userHasAccess(field.access)) return cols;
      }
    }

    if (!isVisible(field)) return cols;

    // if (this.displayedInListing(fields, k)) {

    if (isImage(fields, k)) {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return <img src={rowData[k]} height={70} />;
        },
      });
    } else if (field.type === "number") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (rowData[k] === null) return "-";
          if (typeof field.resource_label !== "undefined") {
            return field.resource_label(rowData);
          }
          return rowData[k]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        },
      });
    } else if (field.type === "enum") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return field.values[rowData[k]];
        },
      });
    } else if (field.type === "datetime") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return moment(rowData[k]).format("DD/MM/YYYY");
        },
      });
    } else if (field.type === "date") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return moment(rowData[k]).format("DD/MM/YYYY");
        },
      });
    } else if (field.type === "lookup") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "undefined")
            return rowData[field.name]
              ? field.values[rowData[field.name]]
              : field.values[0];
          if (typeof field.resource_label !== "function")
            return rowData[field.name]
              ? field.values[rowData[field.name]]
              : field.values[0];
          return field.resource_label(rowData);
        },
      });
    } else if (field.type === "auto") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "function") {
            return field.resource_label(rowData);
          }
          return rowData[field.name];
        },
      });
    } else if (field.type === "radio") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "function") {
            return field.resource_label(rowData);
          }
          return rowData[field.name];
        },
      });
    } else if (field.type === "richtext") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: rowData[field.name] }}
            ></div>
          );
        },
      });
    } else if (field.type === "action") {
      cols.push({
        field: k,
        headerStyle: {
          textAlign: "center",
        },
        title: field.label,
        render: (rowData) => {
          return (
            <div style={{ textAlign: "center" }}>
              <IconButton
                className={{ marginRight: 15 }}
                onClick={(event, rowData) => {
                  this.setState({
                    selectedRow: rowData,
                    actionName: field.name,
                    actionText: field.text,
                    confirm_action:
                      typeof field.component === "undefined" ? true : false,
                    open_action_component:
                      typeof field.component !== "undefined" ? true : false,
                    other_action: field,
                  });
                }}
              >
                <Icon fontSize="small">{field.icon}</Icon>
              </IconButton>
            </div>
          );
        },
      });
    } else {
      cols.push({
        field: k,
        title: field.label,
      });
    }
    // }
    return cols;
  };
  const getLabel = (fields, fieldName) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        return fields[i].label;
      }
    }
  };
  const displayedInListing = (fields, fieldName) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        if (
          typeof fields[i].visible !== "undefined" &&
          fields[i].visible === false
        )
          return false;
      }
    }

    return true;
  };
  const isImage = (fields, fieldName) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        if (fields[i].type === "image") return true;
      }
    }
    return;
  };
  const getField = (fields, fieldName) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        return fields[i];
      }
    }
    return false;
  };
  const getAdditionalActions = () => {
    let actions = [];
    for (let i in moduleProps.actions) {
      let act = moduleProps.actions[i];
      if (typeof act.trigger === "function") {
        actions.push((rowData) => ({
          icon: act.icon,
          tooltip: act.label,
          onClick: (event, rowData) => {
            let act_opt = Object.apply(actionOpts);
            act_opt[act.name] = {
              openWindow:
                typeof act.openWindow !== "undefined" ? act.openWindow : false,
            };
            setSelectedRow(rowData);
            setActionName(act.label);
            setactionOpts(act_opt);
            setConfirmAction(
              typeof act.component === "undefined" ? true : false
            );
            setOpenActionComponent(
              typeof act.component === "undefined" ? true : false
            );
            setOtherAction(act);
          },
          disabled: !act.trigger(rowData),
        }));
      } else {
        actions.push({
          icon: act.icon,
          tooltip: act.label,
          onClick: (event, rowData) => {
            setSelectedRow(rowData);
            setActionName(act.label);
            setActionText(act.text);
            setActionUri(act.uri(rowData));
            setActionOpenWindow(true);
            setConfirmAction(
              typeof act.component === "undefined" ? true : false
            );
            setOpenActionComponent(
              typeof act.component === "undefined" ? true : false
            );
            setOtherAction(act);

            document.location = act.uri(rowData);
          },
        });
      }
    }
    if (typeof moduleProps.edit === "undefined" || moduleProps.edit === true) {
      actions.push({
        icon: "edit",
        tooltip: "Edit",
        onClick: (event, rowData) => {
          setSelectedRow(rowData);
          onEdit(rowData);
        },
      });
    }
    if (
      typeof moduleProps.delete === "undefined" ||
      moduleProps.delete === true
    ) {
      actions.push({
        icon: "delete",
        tooltip: "Delete",
        onClick: (event, rowData) => {
          setDeletedRow(rowData);
          onDelete(rowData);
        },
      });
    }

    actions.push({
      icon: "refresh",
      tooltip: "Refresh Data",
      isFreeAction: true,
      onClick: () => tableRef.current && tableRef.current.onQueryChange(),
    });

    return actions;
  };
  return (
    <MaterialTable
    
      key={moment().unix()}
      tableRef={tableRef}
      columns={cols}
      data={(query) => {
        return new Promise((resolve, reject) => {
          const api = new Api();
          api
            .crud({
              endpoint:
                typeof moduleProps.endpoint === "function"
                  ? moduleProps.endpoint(location.state.data)
                  : moduleProps.endpoint,
              actionType: "LIST",
              data: {
                ...query,
                page: parseInt(query.page),
                per_page: parseInt(query.pageSize),
                search: query.search,
                ...filters,
                ...getFilters(),
              },
            })
            .then((response) => {
              response.totalCount = response.total;
              //setTableQuery(query);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }}
      title={""}
      onChangePage={(page) => {}}
      onChangeRowsPerPage={(pageSize) => {}}
      onRowClick={(evt, row) => {
        setSelectedRow(row);
      }}
      localization={{
        header: {
          actions: typeof moduleProps.defaultActions !== 'undefined' ? moduleProps.defaultActions : "Ubah Data",
        },
      }}
      options={{
          
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        filtering: false,
        actionsColumnIndex: -1,
        headerStyle: {
          backgroundColor: "#dfe4ea",
          color: "#333",
          zIndex: 0,
        },
        rowStyle: (rowData) => ({
          backgroundColor:
            selectedRow && selectedRow.tableData.id === rowData.tableData.id
              ? "#EEE"
              : "#FFF",
        }),
      }}
      actions={getAdditionalActions()}
    />
  );
}
