import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import LabelAndText from "./widgets/LabelAndText";
import LabelAndTextInput from "./widgets/LabelAndTextInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import LabelAndNumberInput from "./widgets/LabelAndNumberInput";
import LabelAndTextArea from "./widgets/LabelAndTextArea";
import ArrayLookup from "./widgets/ArrayLookup";
import ResourceLookup from "./widgets/ResourceLookup";
import ResourceSearchableLookup from "./widgets/ResourceSearchableLookup";

import { DatetimePickerTrigger } from "rc-datetime-picker";

//@TODO dropdown pilih dokter
//@TODO dropdown pilih nomor gigi
import unslug from "../libs/unslug";
import moment from "moment";
import LabelAndImagePicker from "./widgets/LabelAndImagePicker";
import LabelAndFilePicker from "./widgets/LabelAndFilePicker";
import LabelAndVideoPicker from "./widgets/LabelAndVideoPicker";
import LabelAndRadio from "./widgets/LabelAndRadio";

import { userHasAccess } from "../libs/helper";

const getAllowedValues = (values,fields, data)=>{
  let v = {};
  //v = Object.assign(v, values);

  fields.map(field => {
    if(typeof field.access !== 'undefined'){
      if(!userHasAccess(field.access)) return;
    }
    v[field.name] = values[field.name];
  });
  return v;
}
const addDefaultRadioValue = (values,fields,data)=>{
  let v = {};
  v = Object.assign(v, values);
  fields.map(field => {
    if(field.type === "radio"){
      if(typeof v[field.name] === 'undefined'){
      
        if(typeof field.initialValue !== 'undefined'){
          console.log(field.name,field.initialValue);
          v[field.name] = field.initialValue
        }
      }
      if(v[field.name] === null){
        if(typeof field.initialValue !== 'undefined'){
          v[field.name] = field.initialValue
        }
      }
    }
   
  });
  return v;
  
}
const getAutoValues = (values, fields, data) => {
  let v = {};
  v = Object.assign(v, values);

  fields.map(field => {
    if (field.type === "auto") {
      if(typeof field.access !== 'undefined'){
        if(!userHasAccess(field.access)) return;
      }
      if(typeof field.input !== 'undefined'){
        if(!field.input) return;
      }
      if (typeof data !== "undefined" && data !== null) {
        let _val =
          typeof field.value === "function" ? field.value(data) : field.value;
        if (_val !== null) v[field.name] = _val;
      } else {
        if (typeof field.value !== "undefined") {
          if (field.value !== null && field.value !== "null")
            v[field.name] = typeof field.value === "function" ? field.value(data) : field.value;
        }
      }
    }
  });
  return v;
};
const fixDates = (values, fields) => {
  let v = {};
  v = Object.assign(v, values);
  fields.map(field => {
    if (field.type === "datetime") {
      v[field.name] = moment(v[field.name]).format("YYYY-MM-DD hh:mm:ss");
    } else if (field.type === "date") {
      v[field.name] = moment(v[field.name]).format("YYYY-MM-DD");
    }
  });
  return v;
};
const checkMandatories = (values, fields) => {
 
  
  let invalids = [];
  fields.map((field) => {
    let hasValue = true;
    
    if(typeof field.required !== 'undefined' && field.required === true) {
      if(typeof values[field.name] === 'undefined') hasValue = false;
      if(values[field.name] === null) hasValue = false;
      if(typeof values[field.name] === 'string' && values[field.name].length === 0) hasValue = false;
    }
    if(!hasValue){
     invalids.push(field.label);
    }
    
  })
 
  return invalids;
  
  //return isValid;
}
export default function ModuleAddDialog(props) {
  const {
    open,
    onSubmit,
    onClose,
    moduleProps,
    upload,
    uploadAction,
    key,
    data,
    onError,
    customData
  } = props;
  const [update, setUpdate] = useState(0);
  const [values, setValues] = useState({});
  const [store, setStore] = useState({});
  const fields =
    typeof moduleProps.fields !== "undefined" ? moduleProps.fields : [];

  const renderField = (field, index) => {
    if (typeof field.input !== "undefined") {
      if (!field.input) return null;
    }
    if (typeof field.access !== "undefined") {
      if (field.access.length > 0) {
        if (!userHasAccess(field.access)) return null;
      }
    }
    if (field.type === "text") {
      return (
        <LabelAndTextInput
          key={index}
          id={index}
          label={unslug(field.label)}
          onChange={event => {
            let v = values;
            v[field.name] = event.target.value;
            setValues(v);
          }}
        />
      );
    }else if (field.type === "password") {
      return (
        <LabelAndTextInput
          key={index}
          id={index}
          password
          label={unslug(field.label)}
          onChange={event => {
            let v = values;
            v[field.name] = event.target.value;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "textarea") {
      return (
        <LabelAndTextArea
          key={index}
          id={index}
          label={unslug(field.label)}
          onChange={event => {
            let v = values;
            v[field.name] = event.target.value;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "label") {
      return (
        <LabelAndText
          key={index}
          label={field.label}
          labelSize={24}
          labelColor={"#cc0000"}
        />
      );
    } else if (field.type === "date") {
      let v = values;
      if (typeof v[field.name] === "undefined") v[field.name] = moment();
      return (
        <div key={index} style={{ marginBottom: 15 }}>
          <h4 style={{ margin: 0, marginBottom: 5 }}>{field.label}</h4>
          <DatetimePickerTrigger
            moment={v[field.name]}
            showTimePicker={false}
            onChange={date => {
              v[field.name] = date;
              setValues(v);
              setUpdate(update + 1);
            }}
          >
            <input
              type="text"
              value={v[field.name].format("DD-MM-YYYY")}
              readOnly
              style={{
                padding: "6px 10px",
                fontSize: "100%",
                borderRadius: 4,
                border: "1px solid #ccc",
                marginTop: 10,
                width:'100%'
              }}
            />
          </DatetimePickerTrigger>
        </div>
      );
    } else if (field.type === "datetime") {
      let v = values;
      if (typeof v[field.name] === "undefined") v[field.name] = moment();
      return (
        <div key={index} style={{ marginBottom: 15 }}>
          <h4 style={{ margin: 0, marginBottom: 5 }}>{field.label}</h4>
          <DatetimePickerTrigger
            moment={v[field.name]}
            onChange={date => {
              v[field.name] = date;
              setValues(v);
              setUpdate(update + 1);
            }}
          >
            <input
              type="text"
              value={v[field.name].format("DD-MM-YYYY HH:mm")}
              readOnly
              style={{
                padding: "6px 10px",
                fontSize: "100%",
                borderRadius: 4,
                border: "1px solid #ccc",
                marginTop: 10,
                width:'100%'
              }}
            />
          </DatetimePickerTrigger>
        </div>
      );
    } else if (field.type === "number") {
      return (
        <LabelAndNumberInput
          key={index}
          id={index}
          value={values[field.name]}
          label={unslug(field.label)}
          onChange={val => {
            let v = values;
            v[field.name] = val;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "richtext") {
      return (
        <div key={index} style={{ marginTop: 15, marginBottom: 15 }}>
          <div
            style={{
              fontWeight: "bold",
              paddingBottom: 15
            }}
          >
            {unslug(field.label)}
          </div>
          <div
            style={{
              border: "1px solid #ccc",
              padding: 4
            }}
          >
            <CKEditor
              editor={ClassicEditor}
              data={values[field.name]}
              onInit={editor => {
                // You can store the "editor" and use when it is needed.
                // console.log( 'Editor is ready to use!', editor );
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log( { event, editor, data } );
              }}
              onBlur={(event, editor) => {
                let v = values;
                v[field.name] = editor.getData();
                setValues(v);
              }}
              onFocus={(event, editor) => {
                //  console.log( 'Focus.', editor );
              }}
            />
          </div>
        </div>
      );
    } else if (field.type === "image") {
      return (
        <LabelAndImagePicker
          key={index}
          action={uploadAction}
          label={field.name}
          onChange={file_uri => {
            let v = values;
            v[field.name] = file_uri;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "file") {
      return (
        <LabelAndFilePicker
          key={index}
          action={uploadAction}
          label={field.label}
          onChange={file_uri => {
            let v = values;
            v[field.name] = file_uri;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "video") {
      return (
        <LabelAndVideoPicker
          key={index}
          action={uploadAction}
          value={false}
          label={field.label}
          onChange={file_uri => {
            let v = values;
            v[field.name] = file_uri;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "media") {
      return (
        <LabelAndTextInput
          placeholder={"http://"}
          key={index}
          id={index}
          label={unslug(field.label)}
          onChange={event => {
            let v = values;
            v[field.name] = event.target.value;
            setValues(v);
          }}
        />
      );
    } else if (field.type === "auto") {
      let text = "";

      if (typeof field.text !== "undefined") {
        text = typeof field.text === "function" ? field.text(data) : field.text;
      } else if (typeof field.value === "function") {
        text = field.value(data);
      } else {
        text = field.value;
      }
      if(field.label === null) return null;
      return (
        <LabelAndText
          key={index}
          id={index}
          labelSize={18}
          text={text}
          label={unslug(field.label)}
        />
      );
    } else if (field.type === "plugin") {
      let v = values;
      if (typeof v[field.name] === "undefined") v[field.name] = null;
      return field.component({
        data: v[field.name],
        field,
        onUpdate: data => {
         // console.log("data", data);
          v[field.name] = data;
          setValues(v);
        }
      });
    } else if (field.type === "lookup") {

      //look up can be triggered by special conditions
      //by checking the value stored by previous lookup fields.
      if(typeof field.triggerAfter !== 'undefined'){
        let triggerVal = store[field.triggerAfter];
        if(typeof triggerVal === 'undefined') return;
        if(triggerVal === null) return;

        //after we read the value from previous lookup
        //then we can check if these field require specific trigger condition 
        //like if one lookup should have these value, and another lookup field must contain specific value
        //before allowing these lookup field to be displayed.
        if(typeof field.triggerCondition !== 'undefined'){
          console.log("pass condition : ", field.triggerCondition(store), store);
          if(!field.triggerCondition(store)) return;
        }
      }
      if (typeof field.values !== "undefined")
        return (
          <ArrayLookup
            key={index}
            id={field.name + "-" + moment().unix()}
            label={unslug(field.label)}
            values={field.values}
            stores={store}
            onChange={value => {
              let v = values;
              v[field.name] = value;

              if(typeof field.store !== 'undefined'){
                console.log("store: ", value);
                let s = store;
                s[field.store] = value;
                setStore(s);
              }

              setValues(v);
              setUpdate(update+1);
            }}
          ></ArrayLookup>
        );
      else if(field.searchable)
            return (
              <ResourceSearchableLookup
                resource={field.resource}
                id={field.name}
                key={index}
                label={unslug(field.label)}
                values={field.values}
                field={field}
                dataKey={field.key}
                stores={store}
                isMulti={typeof field.multiple !== 'undefined' ? true: false}
                onChange={value => {
                  let v = values;
                  v[field.name] = value;
                  console.log(field.name, value);
                  if(typeof field.store !== 'undefined'){
                    console.log("store: ", value);
                    let s = store;
                    s[field.store] = value;
                    setStore(s);
                  }
                  setValues(v);
                  setUpdate(update+1);
                }}
              />
            );
      else
      return (
        <ResourceLookup
          resource={typeof field.resource === 'function' ? field.resource(customData) : field.resource}
          id={field.name}
          key={index}
          label={unslug(field.label)}
          values={field.values}
          field={field}
          stores={store}
          onChange={value => {
            let v = values;
            v[field.name] = value;
            if(typeof field.store !== 'undefined'){
              console.log("store: ", value);
              let s = store;
              s[field.store] = value;
              setStore(s);
            }
            setValues(v);
            setUpdate(update+1);
          }}
        />
      );
    } else if (field.type === "radio") {
      /*if(typeof field.initialValue !== 'undefined'){
        let v = values;
        v[field.name] = field.initialValue
      }*/
      return (
        <LabelAndRadio
          key={index}
          id={field.name + "-" + moment().unix()}
          label={unslug(field.label)}
          value={typeof field.initialValue !== 'undefined'? field.initialValue: field.values[0] }
          values={field.values}
          onChange={value => {
            let v = values;
            v[field.name] = value;
            setValues(v);
          }}
        ></LabelAndRadio>
      );
    } else {
      return null;
    }
  };
  return (
    <Dialog open={open} fullWidth={true} maxWidth={"md"} style={{top:60}}>
      <DialogTitle id="alert-dialog-slide-title">Tambah Data</DialogTitle>
      <DialogContent>
        {fields.map((field, index) => {
          return renderField(field, index);
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Batalkan
        </Button>
        <Button
          onClick={() => {
            //add auto values here if possible
            console.log('ori',values);
            let final_values = getAllowedValues(values, fields, data);
            console.log('after allowed',final_values);
            final_values = getAutoValues(values,fields,data);
            console.log('after auto',final_values);
            final_values = addDefaultRadioValue(final_values,fields,data);
            final_values = fixDates(final_values, fields);

          //  console.log(final_values); return;
            let invalids = checkMandatories(final_values,fields);
            
            if(invalids.length === 0){
              
              onSubmit(final_values); return;
            }
            if(typeof onError !== 'undefined') onError(invalids);
          }}
          color="primary"
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
    
  );
}
