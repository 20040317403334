import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ResponsiveMenu from "react-responsive-navbar";
import styled from "styled-components";
import {
 
  FaUserAlt,
 
  FaSignOutAlt,
  
} from "react-icons/fa";
import { AppBar, Toolbar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import { userHasAccess } from "../../libs/helper";

const slugify = require("slugify");

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#333"
  },
  menu: {
    minWidth: 200,
    top: 64
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }
}));

export default function Top(props) {
  const { history,modules } = props;
  const username = localStorage.getItem("name");
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  //let roles = localStorage.getItem("roles");
  //let isAdmin = roles.includes("admin");
  let isAdmin = true;
  const handleDrawerOpen = () => {
    setToggleDrawer(true);
  };

  const handleDrawerClose = () => {
    setToggleDrawer(false);
  };
  return (
    <div style={{width:'100%'}}>
      <AppBar position="static" className={classes.appBar} style={{width:'100%'}}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            SHIOKAYA
          </Typography>

        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={toggleDrawer}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <h1>Dashboard</h1>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <List>
          <ListItem>
            <AccountCircle />
            &nbsp;{username}
          </ListItem>
        </List>
        <Divider />
      
        <List>
        {modules.map((item, index) => {
         
          if(typeof item.access !== 'undefined'){
            if(!userHasAccess(item.access)) return null;
          }
          if(typeof item.visible !== 'undefined'){
            if(!item.visible) return null;
          }
          return (
            <ListItem
            button
            key={item.id+"-"+index}
            onClick={() => {
              history.push(
                item.slug
              );
            }}
          >
           
            <ListItemText primary= {item.name} />
          </ListItem>
          
          );
        })}

          <ListItem
            button
            key={"Logout"}
            onClick={() => {
              history.push("/logout");
            }}
          >
            <ListItemIcon>
              <FaSignOutAlt />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
