import React from "react";
import TextField from "@material-ui/core/TextField";
export default function LabelAndTextInput(props) {
  const { label, onChange, value, placeholder, hint, password } = props;

  return (
    <div
      style={{
        marginBottom: "10px",
        ...props.containerStyle,
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          ...props.labelStyle,
        }}
      >
        {label}
      </div>
      {hint ? (
           <div>
           <p dangerouslySetInnerHTML={{ __html: hint }}></p>
         </div>
      ) : null}
     
      <div>
        <TextField
          password={password}
          id="outlined-basic"
          style={{
            width: "100%",
          }}
          placeholder={placeholder}
          defaultValue={value ? value : ''}
          margin="normal"
          variant="outlined"
          inputProps={{
            style: {
              paddingTop: 8,
              paddingBottom: 8,
              ...props.textFieldStyle,
            },
          }}
          onBlur={onChange}
        />
      </div>
    </div>
  );
}
