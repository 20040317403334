/**
 * reference: https://www.npmjs.com/package/reactjs-file-uploader
 */
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import "video-react/dist/video-react.css"; // import css
import { Player } from "video-react";

import Api from "../../Services/Api";
const api = new Api();

export default function LabelAndVideoPicker(props) {
  const { label, onChange, value, action } = props;
  const [progress, setProgress] = useState(false);
  const [fileurl, setFileUrl] = useState( "");
  const [file, setFile] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState(null);

  return (
    <div>
      <div>
        <h4>{label}</h4>
        {progress ? <CircularProgress /> : null}
       
        {file === null && value !== null && value.length > 0 ? (
          <div>
            <Player playsInline src={value} />
          </div>
        ) : null}
         {file !== null ? (
          <div>
            <Player playsInline src={fileurl} />
          </div>
        ) : null}
        <input
          type="file"
          name="file"
          accept="video/mp4,video/x-m4v,video/*"
          onChange={event => {
            setFile(event.target.files[0]);
            setProgress(true);
            api
              .upload(
                "/media/upload",
                {
                  file: event.target.files[0]
                },
                true
              )
              .then(response => {
                setProgress(false);
                setFileUrl(response.file_url);
                onChange(response.file_url);
              })
              .catch(err => {
                setProgress(false);
                console.log(err.message);
              });
          }}
        />
      </div>
    </div>
  );
}
