import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Icon,
  CircularProgress,
  Snackbar,
  Tab,
  Tabs,
  Modal,
  Tooltip,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";

import DashboardStyle from "./styles/DashboardStyles";
import Header from "./common/header";
import ModuleAddDialog from "./ModuleAddDialog";
import ModuleEditDialog from "./ModuleEditDialog";

import unslug from "../libs/unslug";
import { userHasAccess, text } from "../libs/helper";
//service and redux-actions
import Api from "../Services/Api";
import CrudAction from "../redux/actions/CrudAction";
import CrudAddAction from "../redux/actions/CrudAddAction";
import CrudUpdateAction from "../redux/actions/CrudUpdateAction";
import CrudGetAction from "../redux/actions/CrudGetAction";
import CrudDeleteAction from "../redux/actions/CrudDeleteAction";
import moment from "moment";
import DoAction from "../redux/actions/DoAction";
import CallAction from "../redux/actions/CallAction";

import TopLoggedInNav from "./common/TopLoggedInNav";
import ResourceLookup from "./widgets/ResourceLookup";
import ArrayLookup from "./widgets/ArrayLookup";
import ResourceSearchableLookup from "./widgets/ResourceSearchableLookup";
import { Actions } from "../redux/reducers";

import Popup from "reactjs-popup";

class ModuleContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      selectedRow: null,
      deletedRow: null,
      snackbar: false,
      snackbarText: "",
      page: 0,
      per_page: 5,
      search: "",
      open_add_dialog: false,
      open_edit_dialog: false,
      progress: false,
      tableRef: React.createRef(),
      confirm_delete: false,
      actionName: "",
      actionText: null,
      actionOpenWindow: false,
      confirm_action: false,
      open_action_component: false,
      other_action: null,
      filters: null,
      filterChanged: 0,
      fallback: null,
      autoloadFilter: false,
      refreshAccountWhenAdded: false, //use these flag to automatically refresh the account data whenever the add entry successfull
      refreshAccountWhenUpdated: false,
      total_rows: 0,
      showSidebar: window.innerWidth < 600 ? false : true,
      tabValue: 0,
      tableQuery: false,
      on_add_error: false,
      invalids: [],
      actionUri: null,
    };

    this.loadData = this.loadData.bind(this);
    this.handleCrudAdd = this.handleCrudAdd.bind(this);
    this.handleCrudUpdate = this.handleCrudUpdate.bind(this);
    this.handleCrudDelete = this.handleCrudDelete.bind(this);
    this.getAdditionalActions = this.getAdditionalActions.bind(this);
    this.handleDoAction = this.handleDoAction.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.handleFilterChanges = this.handleFilterChanges.bind(this);
    this.compileColumns = this.compileColumns.bind(this);
    this.refreshEmbeddedComponents = this.refreshEmbeddedComponents.bind(this);
    this.renderAutoFilter = this.renderAutoFilter.bind(this);
    this.renderFallbackScreen = this.renderFallbackScreen.bind(this);
    this.handleRefreshAccount = this.handleRefreshAccount.bind(this);
    this.isDataEntryLimited = this.isDataEntryLimited.bind(this);
  }
  componentDidMount() {
    //if(!this.props.moduleProps.crud)
    //this.loadData();
    console.log("yey");
   

  }
  componentWillUnmount() {
   
  }
  componentDidUpdate(_props, _states) {
    const {
      crud_add,
      crud_delete,
      do_action,
      crud_get,
      crud,
      crud_update,
      refresh_account,
      call_action,
    } = this.props;
    const {
      page,
      per_page,
      search,
      tableRef,
      filters,
      filterChanged,
    } = this.state;

    if (_props.crud.payload !== crud.payload) {
      if (crud.payload !== null) {
        tableRef.current.props.data({
          page,
          pageSize: per_page,
          search,
        });
      }
    }
    if (_props.crud_add !== crud_add)
      this.handleCrudAdd(_props.crud_add, crud_add);

    if (_props.crud_update !== crud_update)
      this.handleCrudUpdate(_props.crud_update, crud_update);

    if (_props.crud_delete !== crud_delete)
      this.handleCrudDelete(_props.crud_delete, crud_delete);

    if (_props.do_action !== do_action)
      this.handleDoAction(_props.do_action, do_action);

    if (_states.filterChanged !== filterChanged) {
      this.handleFilterChanges();
    }
    if (_states.filters !== filters) {
      this.handleFilterChanges();
    }

  

    if (call_action.custom_action !== _props.call_action.custom_action) {
      this.handleCustomAction(call_action.custom_action);
    }
  }
  handleCustomAction(payload) {
    const { selectedRow } = this.state;

    if (typeof payload === "undefined") return;
    if (payload === null) return;
    let title = null;
    if (selectedRow !== null) {
      if (typeof selectedRow.nama !== "undefined") {
        title = selectedRow.nama;
      }
      if (typeof selectedRow.name !== "undefined") {
        title = selectedRow.name;
      }
      if (typeof selectedRow.title !== "undefined") {
        title = selectedRow.title;
      }
    }
    let snackbar = true;
    let snackbarText =
      title !== null
        ? `${title} tidak berhasil disimpan`
        : "Tidak berhasil menyimpan perubahan. Silahkan coba kembali !";
    if (payload.status === 1) {
      snackbarText =
        title !== null
          ? `Perubahan ${title} berhasil disimpan`
          : "Perubahan data berhasil disimpan !";
    }
    this.setState({
      snackbar,
      snackbarText,
    });
  }
  shouldComponentUpdate(next_props, next_states) {
    if (next_states.tableQuery !== this.state.tableQuery) {
      return false;
    }
    return true;
  }
  handleRefreshAccount(payload) {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    try {
      localStorage.setItem("roles", payload.roles);
      localStorage.setItem("session_id", payload.user.session_id);
      localStorage.setItem("name", payload.user.name);
      localStorage.setItem("gid", payload.gid);
      localStorage.setItem("mdid", payload.mdid);
      localStorage.setItem("oid", payload.oid);
      if (payload.pemda !== null) {
        localStorage.setItem("profil_id", payload.pemda.id);
        localStorage.setItem("profil_name", payload.pemda.nama);
        localStorage.setItem("pemda", payload.pemda.nama);
      }
      localStorage.setItem("daerah", payload.daerah.nama);
    } catch (err) {}
  }
  handleFilterChanges() {
   // console.log("table refreshed");
    //refresh the listing tables
    this.state.tableRef.current.onQueryChange();

    //also refresh embed components if any
    this.refreshEmbeddedComponents();
  }
  handleDoAction(old, state) {
    if (state.fetching) {
      this.setState({
        confirm_action: false,
        progress: true,
      });
    } else {
      this.setState({ progress: false });
    }

    if (state.payload !== null) {
      this.loadData();
      this.setState({
        snackbar: true,
        snackbarText: "The action completed successfully !",
      });
    }
    if (old.error !== state.error) {
      if (state.error) {
        this.setState({
          snackbar: true,
          snackbarText: "The action cannot be executed, please try again!",
        });
      }
    }
  }
  handleCrudAdd(old, state) {
    const { moduleProps } = this.props;
    const { refreshAccountWhenAdded } = this.state;

    if (state.fetching) {
      this.setState({
        open_add_dialog: false,
        progress: true,
      });
    } else {
      this.setState({ progress: false });
    }

    if (state.payload !== null) {
      this.loadData();
      this.setState({
        snackbar: true,
        snackbarText: "Data berhasil dibuat!",
      });
      if (moduleProps.refreshAccountWhenAdded) {
        this.props.refreshAccount();
      }
    }
  }
  handleCrudUpdate(old, state) {
    const { selectedRow } = this.state;
    const { moduleProps } = this.props;

    if (state.fetching) {
      this.setState({
        open_edit_dialog: false,
        progress: true,
      });
    } else {
      this.setState({ progress: false });
    }

    if (state.payload !== null) {
      this.loadData();
      this.setState({
        snackbar: true,
        snackbarText:
          "Perubahan Data `" +
          selectedRow[moduleProps.updateLabel] +
          "` telah berhasil disimpan !",
      });
     
    }
  }
  handleCrudDelete(old, state) {
    const { deletedRow } = this.state;
    const { moduleProps } = this.props;
    if (state.fetching) {
      this.setState({
        confirm_delete: false,
        progress: true,
      });
    } else {
      this.setState({ progress: false });
    }

    if (state.payload !== null) {
      this.loadData();
      this.setState({
        snackbar: true,
        snackbarText:
          "The Entry `" +
          deletedRow[moduleProps.updateLabel] +
          "` has been removed successfully !",
      });
    }
  }

  refreshEmbeddedComponents() {
    const { callAction, moduleProps, call_action } = this.props;
    const { filters } = this.state;

   // console.log("refresh embedded");
    if (typeof moduleProps.addBefore === "undefined") return;

    //for each components. check if there's a custom component
    //retrieve the endpoint, and execute the CallAction

    //1. components that attached before the main component position
    moduleProps.addBefore.map((EmbeddedComponent, index) => {
      if (typeof EmbeddedComponent.component !== "undefined") {
        let filterValue = this.getFilterValue(EmbeddedComponent.connectFilter);
        if (typeof EmbeddedComponent.endpoint === "function") {
          let endpoint = EmbeddedComponent.endpoint(filterValue);
          callAction(call_action, EmbeddedComponent.name, {
            endpoint,
          });
        }
      }
    });

    if (typeof moduleProps.addAfter === "undefined") return;
    //2. components that attached after the main component position
    moduleProps.addAfter.map((EmbeddedComponent, index) => {
      if (typeof EmbeddedComponent.component !== "undefined") {
        let filterValue = this.getFilterValue(EmbeddedComponent.connectFilter);
        let endpoint = EmbeddedComponent.endpoint(filterValue);
        callAction(call_action, EmbeddedComponent.name, {
          endpoint,
        });
      }
    });
  }
  loadData(reload) {
    const { crudAction, moduleProps } = this.props;
    const { page, per_page, search, tableRef } = this.state;
    /*
    crudAction(moduleProps, {
      per_page,
      page,
      search,
    });*/
    /*
    tableRef.current.props.data({
      page,
      pageSize: per_page,
      search,
    });*/
    //this.state.tableRef.current.onQueryChange();
    //console.log()
  }

  /*
  {
          search: query.search,
          per_page: parseInt(query.pageSize),
          page: parseInt(query.page)
        },*/

  getLabel(fields, fieldName) {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        return fields[i].label;
      }
    }
  }
  displayedInListing(fields, fieldName) {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        if (
          typeof fields[i].visible !== "undefined" &&
          fields[i].visible === false
        )
          return false;
      }
    }

    return true;
  }
  isImage(fields, fieldName) {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        if (fields[i].type === "image") return true;
      }
    }
    return;
  }
  getField(fields, fieldName) {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name === fieldName) {
        return fields[i];
      }
    }
    return false;
  }
  getAdditionalActions() {
    const { moduleProps, doAction } = this.props;
    const { actionOpts } = this.state;
    let actions = [];
    for (let i in moduleProps.actions) {
      let act = moduleProps.actions[i];
      if (typeof act.trigger === "function") {
        actions.push((rowData) => ({
          icon: act.icon,
          tooltip: act.label,
          onClick: (event, rowData) => {
            let act_opt = Object.apply(actionOpts);

            if (typeof act.redirect !== "undefined") {
              if (act.redirect) this.props.history.push(act.uri(rowData));
              return;
            }
            act_opt[act.name] = {
              openWindow:
                typeof act.openWindow !== "undefined" ? act.openWindow : false,
            };
            this.setState({
              selectedRow: rowData,
              actionName: act.label,
              actionOpts: act_opt,
              actionUri: typeof act.uri !== "undefined" ? act.uri : null,
              confirm_action:
                typeof act.component === "undefined" ? true : false,
              open_action_component:
                typeof act.component !== "undefined" ? true : false,
              other_action: act,
            });
          },
          disabled: !act.trigger(rowData),
        }));
      } else {
        actions.push({
          icon: act.icon,
          tooltip: act.label,
          onClick: (event, rowData) => {
            if (typeof act.redirect !== "undefined") {
              if (act.redirect) this.props.history.push(act.uri(rowData));
              return;
            }
            this.setState({
              selectedRow: rowData,
              actionName: act.label,
              actionText: act.text,
              actionUri: act.uri,
              actionOpenWindow:
                typeof act.openWindow !== "undefined" ? act.openWindow : false,
              confirm_action:
                typeof act.component === "undefined" ? true : false,
              open_action_component:
                typeof act.component !== "undefined" ? true : false,
              other_action: act,
            });
          },
        });
      }
    }
    if (typeof moduleProps.edit === "undefined" || moduleProps.edit === true) {
      actions.push({
        icon: "edit",
        tooltip: "Edit",
        onClick: (event, rowData) => {
          this.setState({
            selectedRow: rowData,
            open_edit_dialog: true,
          });
        },
      });
    }
    if (
      typeof moduleProps.delete === "undefined" ||
      moduleProps.delete === true
    ) {
      actions.push({
        icon: "delete",
        tooltip: "Delete",
        onClick: (event, rowData) => {
          this.setState({
            deletedRow: rowData,
            confirm_delete: true,
          });
        },
      });
    }

    actions.push({
      icon: "refresh",
      tooltip: "Refresh Data",
      isFreeAction: true,
      onClick: () =>
        this.state.tableRef.current &&
        this.state.tableRef.current.onQueryChange(),
    });

    return actions;
  }
  getData(props) {
    const { crud, moduleProps } = props;
    let data = [];
    if (!moduleProps.crud) {
      if (typeof crud === "undefined") return [[], []];
      if (typeof crud.payload === "undefined") return [[], []];
      if (crud.payload === null) return [[], []];
      data = crud.payload.data;
    }

    let cols = [];
    if (data.length > 0) {
      let row = data[0];
      for (let k in row) {
        let field = this.getField(moduleProps.fields, k);
        if (!field) continue;
        cols = this.compileColumns(cols, field, k);
      }

      /*this.setState({
        columns: cols
      });*/
    }
    if (cols.length === 0) {
      //if cols still empty, then we use the defined columns setup
      moduleProps.fields.map((field) => {
        let k = field.name;
        cols = this.compileColumns(cols, field, k);
      });
    }

    return [cols, data, 5];
  }
  isVisible(field) {
    if (typeof field.visible === "undefined") return true;
    return field.visible;
  }
  compileColumns(cols, field, k) {
    const { moduleProps } = this.props;

    let fields = [];

    if (typeof field.access !== "undefined") {
      if (field.access.length > 0) {
        if (!userHasAccess(field.access)) return cols;
      }
    }

    if (!this.isVisible(field)) return cols;

    // if (this.displayedInListing(fields, k)) {

    if (this.isImage(fields, k)) {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return <img src={rowData[k]} height={70} />;
        },
      });
    } else if (field.type === "number") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (rowData[k] === null) return "-";
          if (typeof field.resource_label !== "undefined") {
            return field.resource_label(rowData);
          }
          return rowData[k]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        },
      });
    } else if (field.type === "enum") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return field.values[rowData[k]];
        },
      });
    } else if (field.type === "datetime") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return moment(rowData[k]).format("DD/MM/YYYY");
        },
      });
    } else if (field.type === "date") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return moment(rowData[k]).format("DD/MM/YYYY");
        },
      });
    } else if (field.type === "lookup") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "undefined")
            return rowData[field.name]
              ? field.values[rowData[field.name]]
              : field.values[0];
          if (typeof field.resource_label !== "function")
            return rowData[field.name]
              ? field.values[rowData[field.name]]
              : field.values[0];
          return field.resource_label(rowData);
        },
      });
    } else if (field.type === "auto") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "function") {
            return field.resource_label(rowData);
          }
          return rowData[field.name];
        },
      });
    } else if (field.type === "radio") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          if (typeof field.resource_label === "function") {
            return field.resource_label(rowData);
          }
          return rowData[field.name];
        },
      });
    } else if (field.type === "richtext") {
      cols.push({
        field: k,
        title: field.label,
        render: (rowData) => {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: rowData[field.name] }}
            ></div>
          );
        },
      });
    } else if (field.type === "action") {
      cols.push({
        field: k,
        headerStyle: {
          textAlign: "center",
        },
        title: field.label,
        render: (rowData) => {
          return (
            <div style={{ textAlign: "center" }}>
              <Tooltip title={field.label}>
                <IconButton
                  className={{ marginRight: 15 }}
                  onClick={(event, rowData) => {
                    this.setState({
                      selectedRow: rowData,
                      actionName: field.name,
                      actionText: field.text,
                      confirm_action:
                        typeof field.component === "undefined" ? true : false,
                      open_action_component:
                        typeof field.component !== "undefined" ? true : false,
                      other_action: field,
                    });
                  }}
                >
                  <Icon fontSize="small">{field.icon}</Icon>
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      });
    } else {
      cols.push({
        field: k,
        title: field.label,
      });
    }
    // }
    return cols;
  }
  getFilters() {
    const { moduleProps, location } = this.props;

    if (typeof moduleProps.parentFilters === "undefined") return;

    let filters = {};

    moduleProps.parentFilters.map((item) => {
      filters[item.field] = item.value(location.state.data);
    });

    return filters;
  }
  getFilterValue(filterName) {
    if (typeof this.state.filters === "undefined") return;
    if (this.state.filters === null) return;
    if (typeof this.state.filters[filterName] === "undefined") return;
    return this.state.filters[filterName];
  }

  renderAutoFilter(props) {
    const { moduleProps, item } = props;
    const { fallback, filters, filterChanged, autoloadFilter } = this.state;

    let data = item.autoValue();
    
    //add crud filter automatically
    let f = filters;
    if (f === null) f = {};
    if (typeof f[item.parameterName] === "undefined") {
      f[item.parameterName] = data.value;
      this.setState({
        filters: f,
      });
    }


    if (data.label === null) {
      if (fallback === null)
        this.setState({
          fallback: item.name,
        });
      return false;
    }
    if(data.label.length === 0) return;
    
    return (
      <div>
        <h2>{data.label}</h2>
      </div>
    );
  }

  renderFilters() {
    const { moduleProps } = this.props;
    const { filters, filterChanged, fallback } = this.state;

    if (typeof moduleProps.filters !== "undefined") {
      return moduleProps.filters.map((item, index) => {
        if (typeof item.access !== "undefined") {
          
          if (item.access.length > 0) {
            if (!userHasAccess(item.access)) return null;
          }
        }
        if (fallback !== null) return null;
        if (item.resource === false && typeof item.autoValue !== "undefined") {
          //f[item.parameterName] = item.autoValue().value;
          return this.renderAutoFilter({
            moduleProps,
            item,
          });
        }
        if(typeof item.triggerAfter !== 'undefined'){
          if(filters === null) return null;
          if(typeof filters[item.triggerAfter] === 'undefined') return null;
          if(filters[item.triggerAfter]  === null) return null;
          console.log('triggerAfter:',item.triggerAfter, filters[item.triggerAfter]);
        }
        return (
          <div key={"filter-" + index} style={{ marginBottom: 5 }}>
            {typeof item.resource !== "undefined" ? (
              <ResourceSearchableLookup
                resource={typeof item.resource === 'function' ? item.resource(filters[item.triggerAfter]) : item.resource}
                id={item.name}
                key={index}
                label={unslug(item.name)}
                values={item.values}
                field={item}
                stores={{filterValue:filters !== null ? filters[item.triggerAfter]:null}}
                onChange={(value) => {
                  let f = filters;
                  if (f === null) f = {};
                  f[item.parameterName] = value;
                  
                  this.setState({
                    filters: f,
                    filterChanged: filterChanged + 1,
                  });
                }}
              />
            ) : (
              <ArrayLookup
                id={item.name}
                key={index}
                label={unslug(item.name)}
                values={item.values}
                field={item}
                onChange={(value) => {
                  let f = filters;
                  if (f === null) f = {};
                  f[item.parameterName] = value;

                  this.setState({
                    filters: f,
                    filterChanged: filterChanged + 1,
                  });
                }}
              />
            )}
          </div>
        );
      });
    }
  }
  renderTabs() {
    const { moduleProps, history } = this.props;
    const { tabValue, filters } = this.state;
    if (typeof moduleProps.tabs === "undefined") return;
    if (moduleProps.tabs === null) return;
    if(typeof moduleProps.tabs.defaultValue !== 'undefined'){
      
      let f = filters;
      if (f === null) f = {};
      if(typeof f[moduleProps.tabs.parameterName] === 'undefined'){
        f[moduleProps.tabs.parameterName] = moduleProps.tabs.defaultValue;
        this.setState({filters:f})
      }
    }
    return (
      <Tabs
        value={tabValue}
        onChange={(event, newValue) => {
          let f = filters;
          if (f === null) f = {};

          f[moduleProps.tabs.parameterName] =
            moduleProps.tabs.values[newValue].value > -1
              ? moduleProps.tabs.values[newValue].value
              : null;

          this.setState({
            filters: f,
            tabValue: newValue,
          });
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {moduleProps.tabs.values.map((tab, index) => {
          return <Tab label={tab.text} />;
        })}
      </Tabs>
    );
  }
  renderFallbackScreen() {
    const { moduleProps, history } = this.props;
    const { fallback } = this.state;
    let filter = {};
    for (let k in moduleProps.filters) {
      if (
        moduleProps.filters[k].name === fallback &&
        moduleProps.filters[k].resource === false
      ) {
        return moduleProps.filters[k].fallback.component({
          onNextUrl: () => {
            moduleProps.filters[k].fallback.addButton
              ? this.setState({
                  open_add_dialog: true,
                })
              : history.push(moduleProps.filters[k].fallback.next_url);
          },
        });
      }
    }
    return <div>Maaf, halaman tidak tersedia !</div>;
  }
  isDataEntryLimited() {
    const { moduleProps } = this.props;
    const { tableRef } = this.state;

    if (
      typeof moduleProps.addLimit !== "undefined" &&
      moduleProps.addLimit <= tableRef.total_rows
    ) {
      if (userHasAccess(moduleProps.addLimitAccess)) return true;
    }
    return false;
  }
  render() {
    const {
      classes,
      moduleProps,
      modules,
      crud,
      crud_add,
      crud_edit,
      crud_delete,
      crudUpdateAction,
      crudDeleteAction,
      crudAddAction,
      doAction,
      location,
      history,
      call_action,
      callAction,
    } = this.props;

    const {
      columns,
      open_add_dialog,
      open_edit_dialog,
      progress,
      selectedRow,
      confirm_delete,
      deletedRow,
      snackbar,
      snackbarText,
      tableRef,
      actionName,
      confirm_action,
      open_action_component,
      other_action,
      fallback,
      total_rows,
      actionText,
      actionUri,
      actionOpts,
    } = this.state;
    
    let [cols, data, pageSize] = this.getData(this.props);

    let filters = [];

    //parentFilter
    if (
      typeof location.state !== "undefined" &&
      typeof location.state.parent !== "undefined"
    ) {
      moduleProps.parentFilters.map((item) => {
        filters.push(item);
      });
    }

    if (open_action_component) {
      // console.log("transforms", JSON.stringify(moduleProps.transforms),other_action.component,selectedRow);
      history.push("/" + other_action.component, {
        action: other_action.component,
        data: selectedRow,
        parent: {
          slug: moduleProps.slug,
          name: moduleProps.name,
        },
      });
    }
    return (
      <div className={classes.root}>
        <TopLoggedInNav
          history={history}
          
          modules={modules}
          moduleProps={moduleProps}
        />
        <Grid container>
         
          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                marginTop: 80,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <div>
                <h1 style={{ color: "#333" }}>{moduleProps.name}</h1>
              </div>

              {typeof moduleProps.backTo !== "undefined" ? (
                <div
                  style={{
                    marginBottom: 15,
                    textAlign: "right",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      history.push("/" + location.state.parent.slug);
                    }}
                  >
                    Kembali ke {location.state.parent.name}
                  </Button>
                </div>
              ) : (
                ""
              )}

              <div style={{ width: "100%" }}>
                {this.renderFilters()}

                {typeof moduleProps.addBefore !== "undefined"
                  ? moduleProps.addBefore.map((EmbeddedComponent, index) => {
                    //console.log("visibility", EmbeddedComponent.name, EmbeddedComponent.visible)
                      if(typeof EmbeddedComponent.visible !== 'undefined'){
                        if(!EmbeddedComponent.visible) return null;
                      }
                      if (typeof EmbeddedComponent.component !== "undefined") {
                        let filterValue = this.getFilterValue(
                          EmbeddedComponent.connectFilter
                        );

                        if (
                          typeof filterValue === "undefined" &&
                          !EmbeddedComponent.static
                        )
                          return null;
                        if (filterValue === null && !EmbeddedComponent.static)
                          return null;

                        return (
                          <div
                            id={index}
                            style={{
                              marginBottom: 15,

                              padding: 10,
                            }}
                          >
                            <EmbeddedComponent.component
                              filterValue={filterValue}
                              defaults={EmbeddedComponent.defaults}
                              data={
                                typeof call_action[EmbeddedComponent.name] !==
                                "undefined"
                                  ? call_action[EmbeddedComponent.name]
                                  : null
                              }
                              parent={
                                typeof location.state !== "undefined"
                                  ? location.state.parent
                                  : null
                              }
                              transforms={moduleProps.transforms}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={index}
                            id={index}
                            style={{
                              marginBottom: 15,
                              border: "1px solid #ccc",
                              padding: 10,
                            }}
                          >
                            <EmbeddedComponent
                              data={
                                typeof location.state !== "undefined"
                                  ? location.state.data
                                  : null
                              }
                              parent={
                                typeof location.state !== "undefined"
                                  ? location.state.parent
                                  : null
                              }
                              transforms={moduleProps.transforms}
                            />
                          </div>
                        );
                      }
                    })
                  : ""}

                {typeof moduleProps.add === "undefined" ||
                (moduleProps.add === true &&
                  fallback === null &&
                  !this.isDataEntryLimited()) ? (
                  <div
                    style={{
                      marginBottom: 15,
                      textAlign: "right",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.setState({
                          open_add_dialog: true,
                        });
                      }}
                    >
                      Tambah {moduleProps.singularName}
                    </Button>
                  </div>
                ) : null}
                {this.renderTabs()}

                {fallback === null ? (
                  <MaterialTable
                    key={moment().unix()}
                    tableRef={this.state.tableRef}
                    columns={cols}
                    data={(query) => {
                      return new Promise((resolve, reject) => {
                        const api = new Api();
                        api
                          .crud({
                            endpoint:
                              typeof moduleProps.endpoint === "function"
                                ? moduleProps.endpoint(location.state.data)
                                : moduleProps.endpoint,
                            actionType: "LIST",
                            data: {
                              ...query,
                              page: parseInt(query.page),
                              per_page: parseInt(query.pageSize),
                              search: query.search,
                              ...this.state.filters,
                              ...this.getFilters(),
                            },
                          })
                          .then((response) => {
                            response.totalCount = response.total;
                            // this.setState({ total_rows: response.total });
                            // this.state.tableRef.total_rows = response.total;
                            this.setState({ tableQuery: query });
                            resolve(response);
                          })
                          .catch((error) => {
                            reject(error);
                          });
                      });
                    }}
                    title={""}
                    onChangePage={(page) => {
                      // this.setState({ page });
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                      // this.setState({ per_page: pageSize });
                    }}
                    onRowClick={(evt, row) =>
                      this.setState({ selectedRow: row })
                    }
                    localization={{
                      header: {
                        actions: "Ubah Data",
                      },
                    }}
                    options={{
                      pageSize: 20,
                      pageSizeOptions: [20, 50, 100],
                      filtering: false,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: "#dfe4ea",
                        color: "#333",
                        zIndex: 0,
                      },
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          this.state.selectedRow &&
                          this.state.selectedRow.tableData.id ===
                            rowData.tableData.id
                            ? "#EEE"
                            : "#FFF",
                      }),
                    }}
                    actions={this.getAdditionalActions()}
                  />
                ) : (
                  this.renderFallbackScreen()
                )}
              </div>

              {typeof moduleProps.addAfter !== "undefined"
                ? moduleProps.addAfter.map((EmbeddedComponent, index) => {
                    if (typeof EmbeddedComponent.component !== "undefined") {
                      let filterValue = this.getFilterValue(
                        EmbeddedComponent.connectFilter
                      );

                      if (typeof filterValue === "undefined") return null;
                      if (filterValue === null) return null;
                      return (
                        <div
                          id={index}
                          style={{
                            marginBottom: 15,
                            border: "1px solid #ccc",
                            padding: 10,
                          }}
                        >
                          <EmbeddedComponent.component
                            data={
                              typeof call_action[EmbeddedComponent.name] !==
                              "undefined"
                                ? call_action[EmbeddedComponent.name]
                                : null
                            }
                            parent={
                              typeof location.state !== "undefined"
                                ? location.state.parent
                                : null
                            }
                            transforms={moduleProps.transforms}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          id={index}
                          style={{
                            marginBottom: 15,
                            border: "1px solid #ccc",
                            padding: 10,
                          }}
                        >
                          <EmbeddedComponent
                            data={
                              typeof location.state !== "undefined"
                                ? location.state.data
                                : null
                            }
                            parent={
                              typeof location.state !== "undefined"
                                ? location.state.parent
                                : null
                            }
                            transforms={moduleProps.transforms}
                          />
                        </div>
                      );
                    }
                  })
                : ""}
            </div>
          </Grid>
        </Grid>

        <ModuleAddDialog
          open={open_add_dialog}
          moduleProps={moduleProps}
          data={
            typeof location.state !== "undefined" &&
            typeof location.state.data !== "undefined"
              ? location.state.data
              : null
          }
          onSubmit={(values) => {
            crudAddAction(moduleProps, values);
          }}
          onClose={() => {
            this.setState({
              open_add_dialog: false,
            });
          }}
          onError={(invalids) => {
            this.setState({
              on_add_error: true,
              invalids,
            });
          }}
        />
        <ModuleEditDialog
          open={open_edit_dialog}
          moduleProps={moduleProps}
          data={selectedRow}
          onSubmit={(values) => {
            crudUpdateAction(moduleProps, selectedRow.id, values);
          }}
          onClose={() => {
            this.setState({
              open_edit_dialog: false,
            });
          }}
        />

        <Dialog
          open={confirm_delete}
          onClose={() => {
            this.setState({
              confirm_delete: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Hapus Data ?"}</DialogTitle>
          <DialogContent>
            {deletedRow !== null
              ? "data `" +
                (typeof deletedRow.name !== "undefined"
                  ? deletedRow.name
                  : deletedRow[moduleProps.updateLabel]) +
                "` akan dihapus secara permanen. Apakah anda yakin ?"
              : ""}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  confirm_delete: false,
                });
              }}
              color="primary"
            >
              BATALKAN
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  confirm_delete: false,
                });
                crudDeleteAction(moduleProps, deletedRow.id);
              }}
              color="primary"
              autoFocus
            >
              HAPUS
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirm_action}
          onClose={() => {
            this.setState({
              confirm_action: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{actionName}</DialogTitle>
          <DialogContent>
            {selectedRow !== null
              ? actionText
                ? actionText(selectedRow)
                : actionName +
                  " " +
                  selectedRow[moduleProps.updateLabel] +
                  ". Apakah anda yakin ? "
              : ""}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  confirm_action: false,
                });
              }}
              color="primary"
            >
              BATALKAN
            </Button>
            <Button
              onClick={() => {
                console.log("DIALOG OPEN");
                if (actionUri) {
                  if (this.state.actionOpenWindow) {
                    window.open(actionUri(selectedRow));
                  } else {
                    console.log(actionName, 'uri',actionUri(selectedRow))
                    callAction(call_action, "custom_action", {
                      endpoint: actionUri(selectedRow),
                      scenario: "GET",
                    });
                  }
                } else {
                  console.log('no action uri');
                  doAction(moduleProps, actionName, selectedRow.id);
                }
                this.setState({
                  confirm_action: false,
                });
                /*crudDeleteAction(moduleProps, deletedRow.id);*/
              }}
              color="primary"
              autoFocus
            >
              KONFIRMASI
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.on_add_error}
          onClose={() => {
            this.setState({
              confirm_action: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Maaf, data berikut ini harus diisi!
          </DialogTitle>
          <DialogContent>
            <ul>
              {this.state.invalids.map((invalid, idx) => {
                return <li key={idx}>{invalid}</li>;
              })}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  on_add_error: false,
                  invalids: [],
                });
              }}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {progress ? <CircularProgress size={30} /> : null}
        <Snackbar
          open={snackbar}
          message={snackbarText}
        ></Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    crud,
    crud_add,
    crud_delete,
    crud_update,
    crud_get,
    do_action,
    call_action,
  
  } = state;
  return {
    crud,
    crud_add,
    crud_delete,
    crud_update,
    crud_get,
    do_action,
    call_action,
   
  };
};

const mapDispatchToProps = (dispatch) => ({
  crudAction: (service, data) => dispatch(CrudAction(service, data)),
  crudAddAction: (service, data) => dispatch(CrudAddAction(service, data)),
  crudUpdateAction: (service, id, data) =>
    dispatch(CrudUpdateAction(service, id, data)),
  crudDeleteAction: (service, id, data) =>
    dispatch(CrudDeleteAction(service, id, data)),
  crudGetAction: (service, id, data) =>
    dispatch(CrudGetAction(service, id, data)),
  doAction: (service, actionName, id) =>
    dispatch(DoAction({ service, actionName, id })),
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyle)(ModuleContainer));
