import React, { useState, useEffect } from "react";
import { Select, Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Api from "../../Services/Api";
//import AsyncSelect from "react-select";

export default function ResourceLookup(props) {
  const {
    open,
    handleClose,
    handleOpen,
    onChange,
    id,
    label,
    resource,
    field
  } = props;

  const api = new Api();

  const [value, setValue] = useState("");
  const [values, setValues] = useState([]);

  useEffect(() => {
    //retrieve lookup
    api
      .crud({
        endpoint: "/" + resource,
        actionType: "LOOKUP",
        data: {}
      })
      .then(response => {
       
        setValues(response.data);
        setValue(value);
      })
      .catch(err => {
        console.log(err);
        setValues([]);
      });
   
  }, [resource]);

  useEffect(() => {
    setValue(props.value);
  }, [values]);
  if (typeof values === "undefined") return "Loading...";

  return (
    <div key={id} style={{ marginTop: 15, marginBottom: 15 }}>
      <div
        style={{
          fontWeight: "bold",
          paddingBottom: 15
        }}
      >
        {label}
      </div>
     
      <Select
        labelId={id}
        id={props.value}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        defaultValue={typeof props.value !== "undefined" ? props.value : null}
        value={value}
        onChange={evt => {
          setValue(evt.target.value);
          onChange(evt.target.value);
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {values.map((item, index) => {
          if (typeof field.value === "function") {
            return (
              <MenuItem key={index} value={item[field.key]}>
                {field.value(item)}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={index} value={item[field.key]}>
              {item[field.resource_label]}
            </MenuItem>
          );
        })}
      </Select>
    
    </div>
  );
}
